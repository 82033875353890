<template>
    <div class="block-clock-container" :class="[device_platform]">
        <div class="bclock-megatimer-container"
             @click="getCall({type: 'sale', form:'main-clock'})">
            <div class="bclock-header">
                ДО КОНЦА АКЦИИ ОСТАЛОСЬ
            </div>
            <!-- Timer injected here from index.html script -->
        </div>

        <div class="bclock-clock">
            <div v-for="stat in date_parts" class="bclock-clock-stat">
                <div class="bclock-clock-stat__value">
                    {{stat.value}}
                </div>
                <div class="bclock-clock-stat__name">
                    {{stat.name}}
                </div> 
            </div>
        </div>
        <div class="bclock-instructions">
            Звоните
            <a class="bclock-phone comagic_phone2" :href="'tel:' + CONSTANTS.phone">
                {{CONSTANTS.phone}}
            </a>
        </div>
        <div class="bclock-callback">
            <div class="button button button_common"
                 @click="getCall({type: 'sale', form:'countdown'})">
                Оставить заявку
            </div>
        </div>
    </div>
</template>

<script>
    import Mixin from '../common/mixin';
    import CF from '../common/common-functions';

    export default {
        name: 'block-clock',
        components: {},
        directives: {},
        mixins: [Mixin],
        props: [''],
        data() {
            return {
                time: new Date(2019, 11, 31, 14, 0, 0),
                date_parts: [
                    {
                        name: 'Дней',
                        units: 'days',
                        value: 0,
                        period: 0,
                    },
                    {
                        name: 'Часов',
                        units: 'hours',
                        value: 0,
                        period: 24,
                    },
                    {
                        name: 'Минут',
                        units: 'minutes',
                        value: 0,
                        period: 60,
                    },
                    {
                        name: 'Секунд',
                        units: 'seconds',
                        value: 0,
                        period: 60,

                    }
                ]
            }
        },
        created() {
            let small_part_multiplier = 1,
                time_left = (this.CONSTANTS.end_date.getTime() - Date.now()) / 1000;
            for (let i = this.date_parts.length - 1; i >= 0; --i) {
                let part = this.date_parts[i];
                part.value = Math.floor((time_left / small_part_multiplier) % (part.period || 10000));
                part.name = this.getName(part);
                small_part_multiplier = small_part_multiplier * part.period;
            }
            setInterval(this.clockTick, 1000);
        },
        mounted() {
            let today = new Date(),
              next = this.time;

            if (today > next) {
                var timer_block = document.getElementById('timer_block_2');
                timer_block.classList.remove('hidden_clock');
                this.$el.childNodes[0].appendChild(timer_block);
            } else {
                var timer_block = document.getElementById('timer_block');
                timer_block.classList.remove('hidden_clock');
                this.$el.childNodes[0].appendChild(timer_block);
            }
        },
        computed: {},
        methods: {
            clockTick() {
                for (let i = this.date_parts.length - 1; i >= 0; --i) {
                    if (this.date_parts[i].value > 0) {
                        --this.date_parts[i].value;
                        this.date_parts[i].name = this.getName(this.date_parts[i]);
                        return;
                    }
                    this.date_parts[i].value = this.date_parts[i].period - 1;
                    this.date_parts[i].name = this.getName(this.date_parts[i]);
                }
            },
            getName(date_part) {
                return CF.getNumericalName(date_part.value, date_part.units);
            }
        }
    }
</script>

<style scoped lang="scss">

    .desktop {
        &.block-clock-container {
            width: 100%;
            margin-top: -140px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            z-index: 1;
            max-width: 1920px;

            .bclock-megatimer-container {
                width: 100%;
                max-width: 600px;
                padding-bottom: 20px;
                background: #1f6fb6;
                font-size: 30px;
                font-weight: 600;
                text-align: left;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding-left: 25px;

                .bclock-header {
                    padding: 10px 0 20px 0px;
                    text-align: left;
                    color: #fff;
                    font-size: 28px;
                    font-weight: 500;

                    div {
                        font-size: 36px;
                    }
                }
                &::after {
                    content: "";
                    display: block;
                    height: 100%;
                    width: 150px;
                    background-color: #1f6fb6;
                    position: absolute;
                    top: 0;
                    left: 420px;
                    transform: translateX(100%) skewX(30deg);
                    transform-origin: bottom;
                }
            }
        }
    }

    .tablet {
        &.block-clock-container {
            padding: 0px 0 50px;

            .bclock-header {
                margin-bottom: 30px;
                font-size: 34px;
            }
        }
    }

    .mobile {
        &.block-clock-container {
            margin-bottom: 40px;
        }
        .bclock-instructions {
            max-width: 250px;
            font-size: 22px;
        }
        .bclock-header {
            font-size: 20px;
            max-width: 400px;
            margin: 20px 30px 30px;

            div {
                font-size: 30px;
            }
        }
        .bclock-clock {
            flex-wrap: wrap;
            max-width: 200px;
        }
    }

    .bclock-megatimer-container {
        cursor: pointer;
    }

    .bclock-phone {
        white-space: nowrap;
    }

    .bclock-header {
        font-size: 40px;
        font-weight: bold;
        text-align: center;
    }

    .bclock-clock {
        display: flex;
        justify-content: center;
        margin: 10px;
    }

    .bclock-clock-stat {
        width: 75px;
        height: 90px;
        margin: 5px;
        box-shadow: 1px 2px 0 0 rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 4px;
    }

    .bclock-clock-stat__value {
        font-size: 26px;
        font-weight: bold;
    }

    .bclock-clock-stat__name {
        font-size: 16px;
        font-weight: 400;
    }

    .bclock-instructions {
        font-size: 24px;
        font-weight: bold;
        margin: 20px;
        text-align: center;
    }

    .button_common {
        width: 320px;
    }

    .custom_clock {
        width: 460px;
    }
</style>
