<template>
  <div class="relative overflow-hidden bg-primary-blue">
    <div class="container py-5 lg:py-14">
      <div class="block3 block3--credit" id="credit">
        <div
          class="block3_content mx-auto flex flex-row items-end justify-between max-xl:flex-col"
        >
          <div
            class="block3_content_left mb-8 flex w-full flex-col text-left max-md:w-full xl:mb-4 xl:w-2/3"
          >
            <p
              class="mb-5 text-xl font-medium uppercase text-white md:text-2xl 2xl:text-3xl"
            >
              Рассчитайте платёж в рассрочку и узнайте условия
            </p>
            <p
              class="text-xl font-normal leading-none text-[#c0c0c0] 2xl:text-2xl"
            >
              без визита в автосалон за&nbsp;15&nbsp;минут
            </p>
            <img
              class="max-h-96 mt-7 flex w-full object-cover xl:max-h-[500px] xl:object-center"
              src="../images/credit/credit-main.jpg"
              alt="Рассчитайте платёж в рассрочку и узнайте условия"
            />
          </div>
          <div
            class="block3_content_right mb-8 flex w-full flex-col px-4 max-md:mb-0 max-md:w-full xl:mb-4 xl:w-1/3"
          >
            <vee-form
              :validation-schema="schema"
              novalidate
              class="flex h-full flex-col justify-between gap-x-4 gap-y-4 lg:gap-y-8"
              @submit="onSubmit"
            >
              <div class="form_item max-xs:w-full">
                <p class="mb-2 text-base font-medium leading-tight text-white">
                  Выберите авто
                </p>
                <vee-field v-slot="{ handleChange, errorMessage }" name="brand">
                  <v-select
                    theme="white"
                    v-model="brand"
                    :options="brands"
                    label="text"
                    placeholder="Выберите модель"
                    :error="errorMessage"
                    @update:modelValue="handleChange"
                  ></v-select>
                </vee-field>
              </div>
              <div
                class="form_card flex w-full justify-between space-y-4 max-xs:flex-col xs:space-y-0"
              >
                <div class="form_item w-1/2 max-xs:w-full">
                  <p
                    class="mb-2 text-base font-medium leading-tight text-white"
                  >
                    Первоначальный взнос
                  </p>
                  <vee-field v-slot="{ handleChange, errorMessage }" name="fee">
                    <v-select
                      theme="white"
                      v-model="fee"
                      :options="fees"
                      label="text"
                      placeholder="Выберите взнос"
                      :error="errorMessage"
                      @update:modelValue="handleChange"
                    ></v-select>
                  </vee-field>
                </div>
                <div class="form_item w-1/3 max-xs:w-full">
                  <p
                    class="mb-2 text-base font-medium leading-tight text-white"
                  >
                    Срок рассрочки
                  </p>
                  <vee-field
                    v-slot="{ handleChange, errorMessage }"
                    name="year"
                  >
                    <v-select
                      theme="white"
                      v-model="year"
                      :options="years"
                      label="text"
                      placeholder="Выберите срок"
                      :error="errorMessage"
                      @update:modelValue="handleChange"
                    ></v-select>
                  </vee-field>
                </div>
              </div>
              <div class="form_item w-full">
                <p class="mb-2 text-base font-medium leading-tight text-white">
                  Контактный телефон
                </p>
                <vee-field v-slot="{ handleChange, errorMessage }" name="phone">
                  <v-input
                    v-model="phone"
                    placeholder="+7 (___) ___-__-__"
                    theme="white"
                    format="phone"
                    :error="errorMessage"
                    @update:modelValue="handleChange"
                  />
                </vee-field>
              </div>
              <button
                class="btn-blue relative mx-auto flex gap-x-2 after:h-4 after:w-4 after:-rotate-45 after:border-b-2 after:border-r-2 after:border-white after:content-['']"
                type="submit"
                name="button"
              >
                <span class="btn__text btn__text_theme_dark"
                  >РАССЧИТАТЬ ПЛАТЁЖ</span
                >
              </button>
            </vee-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form } from 'vee-validate';
import * as Yup from 'yup';
import VSelect from './common/VSelect.vue';
import VInput from './common/VInput.vue';
import CarsInfo from '../common/cars-info';
import emitter from 'tiny-emitter/instance';
export default {
  name: 'block-credit',
  data() {
    return {
      brand: null,
      fee: null,
      year: null,
      phone: null,
    };
  },

  setup() {
    const schema = Yup.object().shape({
      brand: Yup.mixed().nullable(false).required(),
      fee: Yup.mixed().nullable(false).required(),
      year: Yup.mixed().nullable(false).required(),
      phone: Yup.string().required(),
    });
    return {
      schema,
    };
  },

  props: {},

  components: {
    VInput,
    VSelect,
    VeeForm: Form,
    VeeField: Field,
  },

  created() {},

  mounted() {},

  beforeDestroy() {},

  methods: {
    onSubmit(value, actions) {
      emitter.emit('callBack', {
        phone: this.phone,
        brand: this.brand,
        fee: this.fee,
        data: {
          type: 'credit',
          form: 'credit',
        },
      });

      this.brand = this.fee = this.year = this.phone = null;
      setTimeout(() => {
        actions.resetForm();
      }, 1000);
    },
  },

  computed: {
    brands() {
      return CarsInfo.CARS.filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj['name']).indexOf(obj['name']) === pos;
      }).map((x) => ({ text: x.name }));
    },
    fees() {
      return [1, 2, 3, 4, 5, 6].map((x) => ({
        text: new Intl.NumberFormat('ru-RU').format(x * 100000) + ' ₽',
      }));
    },
    years() {
      return [1, 2, 3, 4, 5, 6].map((x) => ({
        text: x * 6 + ' мес.',
      }));
    },
  },

  watch: {},

  directives: {},

  filters: {},
};
</script>
