<template>
  <div class="c-appModal" >
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        class="fixed inset-0 z-40 bg-black/80" v-if="visible"
      />
    </transition>
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInUp"
      leave-active-class="animated fadeOutDown"
    >
      <div
        v-if="visible"
        class="fixed inset-0 z-50 flex justify-center items-center"
        @click.self="modalStore.hideModal()"
      >
        <div class="p-4 flex h-auto w-full flex-col items-center overflow-hidden max-w-full md:max-w-3xl ">
          <component :is="{...component}"/>
        </div>
      </div>
    </transition>

  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { defineAsyncComponent, ref, watch, onMounted } from 'vue'
import { useModalStore } from "@/stores/modalStore";

  const component = ref(null)
  const modalStore = useModalStore()
  const { modalVisible, modalComponent } = storeToRefs(modalStore)
  const visible = modalVisible
  watch( modalComponent, (componentName) => {
    if (!componentName) return;

      const AsyncComp = defineAsyncComponent(() =>
        import(`./modals/${componentName}.vue`)
      )

      component.value = AsyncComp;
  })
  onMounted(() => {
    const escapeHandler = (e) => {
      if (e.key === `Escape` && visible) {
        modalStore.hideModal();
      }
    };

    document.addEventListener(`keydown`, escapeHandler);
  })
</script>

<style scoped>
.animated {
	 animation-duration: 0.3s;
	 animation-fill-mode: both;
}
 .fadeIn {
	 animation-name: fadeIn;
}
 @keyframes fadeIn {
	 from {
		 opacity: 0;
	}
	 to {
		 opacity: 1;
	}
}
 .fadeOut {
	 animation-name: fadeOut;
}
 @keyframes fadeOut {
	 from {
		 opacity: 1;
	}
	 to {
		 opacity: 0;
	}
}
 .fadeInUp {
	 animation-name: fadeInUp;
}
 @keyframes fadeInUp {
	 from {
		 opacity: 0;
		 transform: translate3d(0, 100%, 0);
	}
	 to {
		 opacity: 1;
		 transform: none;
	}
}
 .fadeOutDown {
	 animation-name: fadeOutDown;
}
 @keyframes fadeOutDown {
	 from {
		 opacity: 1;
	}
	 to {
		 opacity: 0;
		 transform: translate3d(0, 100%, 0);
	}
}
 
</style>