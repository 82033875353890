<template>
  <div class="block-contacts-container" :class="device_platform">
    <div class="w-full h-[900px]">
      <img
        ref="capEl"
        class="absolute right-0 top-0 h-full w-full cursor-pointer object-cover object-right"
        src="../images/backgrounds/2023-11-03_13-19-02.png"
        alt="map cap"
      >
      <div id="ya-karto">
        <div class="input-container">
          <div class="logos-container">
            <img src="../images/logos/kuntsevo.png" alt="" />
          </div>
          <div class="mt-[35px]">
            <a
              :href="'tel:' + getDealerInfo.phone.replace(/\+|\(|\)|\s|\-/g, '')"
              class="text-[26px] font-medium"
              >{{ getDealerInfo.phone }}</a
            >
            <p
              class="mt-[25px] text-[16px] font-medium md:text-[20px] 2xl:mt-[30px]"
            >
              г. {{ getDealerInfo.city }}, {{ getDealerInfo.address }}
            </p>
            <p
              class="mt-[25px] text-[16px] font-medium md:text-[20px] 2xl:mt-[30px]"
            >
              Отдел продаж: {{ getDealerInfo.opening_hours }} -
              {{ getDealerInfo.closing_hours }}
            </p>
            <div
              @click="
                modalStore.showModal({
                  component: 'ModalCallback',
                  title: 'ОСТАВИТЬ ЗАЯВКУ',
                  button: 'ОТПРАВИТЬ',
                })
              "
              class="mt-[25px] flex cursor-pointer items-center justify-center bg-[#002E5D] py-[22px] uppercase text-white 2xl:mt-[40px]"
            >
              Оставить заявку
            </div>
          </div>
        </div>
        <div class="reviews-container">
          <div class="review-wrapper" v-for="review in getDealerInfo.reviews">
            <div class="review-header">{{ review.name }}</div>
            <div class="review-info-container">
              <div class="stars-row">
                <div class="star" v-for="i in 5"></div>
              </div>
              <div class="review-date">{{ review.date }}</div>
            </div>
            <div class="review-text" :class="{ expanded: review.expanded }">
              {{ review.text }}
            </div>
            <div
              class="review-expander"
              @click="review.expanded = !review.expanded"
            >
              {{ review.expanded ? 'Скрыть' : 'Читать целиком' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CallbackInput from './callback-form/callback-input';
import Mask from '../common/mask';
import Mixin from '../common/mixin';
import Keyup from '../common/keyup';
import { useDealerStore } from '@/stores/dealerStore';
import { useModalStore } from '@/stores/modalStore';

export default {
  name: 'block-map',
  components: { CallbackInput},
  directives: { Mask, Keyup },
  mixins: [Mixin],
  props: [''],
  setup() {
    const dealerStore = useDealerStore();
    const modalStore = useModalStore();
    dealerStore.getAllDealers();
    return {
      dealerStore,
      modalStore,
    };
  },
  data() {
    return {
      capEl: null,
      showMap: false,
    }
  },
  computed: {
    getDealerInfo() {
      return this.dealerStore.getDealerById(1);
    },
  },
  methods: {
    initYandexMap() {
      const isMobile = {
        Android: function () {
          return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function () {
          return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function () {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function () {
          return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function () {
          return navigator.userAgent.match(/IEMobile/i);
        },
        any: function () {
          return (
            isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows()
          );
        },
      };
      let zoomControl = new ymaps.control.ZoomControl({
        options: {
          position: {
            left: 'auto',
            right: 10,
            top: 108,
          },
        },
      });

      let center_x = this.CONSTANTS.center_coords.x,
        center_y = this.CONSTANTS.center_coords.y;

      if (this.device_platform === 'tablet') {
        center_x = center_x;
        center_y = center_y;
      } else if (this.device_platform === 'mobile') {
        center_x = center_x;
        center_y = center_y;
      }

      let myMap = new ymaps.Map(
        'ya-karto',
        {
          center: this.dealerStore.getDealerById(1).coords,
          zoom: 16,
          controls: [],
        },
        {
          suppressMapOpenBlock: true,
          searchControlProvider: 'yandex#search',
        },
      );
      myMap.controls.add(zoomControl);
      myMap.geoObjects.add(
        new ymaps.Placemark(
          this.dealerStore.getDealerById(1).coords,
          {
            iconContent: this.dealerStore.getDealerById(1).name,
            hintContent: '',
            balloonContent: this.dealerStore.getDealerById(1).address,
          },
          {
            iconLayout: 'default#image',
            iconImageHref: require(`../images/map-baloon.png`),
            iconImageSize: [60, 60],
            iconImageOffset: [-5, -38],
          },
        ),
      );

      if (isMobile.any() || this.device_platform !== 'desktop') {
        myMap.behaviors.disable('drag');
      }
      myMap.behaviors.disable('scrollZoom');
    },
  },
  watch: {
    showMap(value) {
      if (value) {
        let script = document.createElement('script');
        script.src =
          '//api-maps.yandex.ru/2.1/?apikey=0cd73082-8549-42eb-b78d-88f8699620e3&load=package.standard&lang=ru_RU';
        document.head.appendChild(script);
        script.onload = () => {
          ymaps.ready(this.initYandexMap);
        };
      }
    }
  },
  mounted() {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: [0.0, 0.75],
    };

    let callback = function (entries, observer) {
      for (const entry of entries) {
        if (entry.intersectionRatio > 0) {
          this.showMap = true;
        }
      }
    }.bind(this);

    let observer = new IntersectionObserver(callback, options);
    observer.observe(this.$refs.capEl);
  }
};
</script>

<style scoped lang="scss">
@import '../styles/constants';
.mobile {
  &.block-contacts-container {
    margin-bottom: 450px;
    display: flex;
    flex-direction: column;
    // margin-top: 30px;

    .input-container {
      width: 100%;
      transform: translateX(-50%);
      box-shadow: none;
      @apply absolute left-0 top-0 order-1 max-w-full translate-x-0 py-[40px] md:absolute md:left-[9%] md:top-1/2 md:max-w-[330px] md:-translate-y-1/2 md:px-[10px] md:py-[30px];
    }

    .office-contacts-container {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 20px;
    }

    .phone-container {
      margin-bottom: 20px;
    }

    .callback-input {
      width: 100%;
    }

    .dealer-text {
      width: 120%;
    }

    .dealer {
      font-size: 22px;
    }
  }

  .reviews-container {
    top: 900px;
    position: absolute;
    right: 0;
    width: 100%;
    transform: translateX(0);
    box-shadow: none;
    background: none;
    max-height: 450px;
    max-width: 100%;
  }
}

.tablet {
  #ya-karto {
    // margin-top: 280px;
  }

  .input-container {
    width: 100%;
    padding: 30px;
    // left: 0;
    border-top: 10px solid $blue;
    top: 50%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    @apply absolute left-0 top-0 order-1 max-w-full translate-x-0 py-[40px] md:absolute md:left-[9%] md:top-1/2 md:max-w-[330px] md:-translate-y-1/2 md:px-[10px] md:py-[30px];
  }

  .logos-container {
    width: 50%;
    max-width: 350px;
  }

  .office-contacts-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    text-align: right;
    margin-top: 0;
    width: 50%;
    max-width: 350px;
  }

  .callback-input {
    width: 100%;
    max-width: 800px;
  }
}

.block-contacts-container {
  width: 100%;
  position: relative;
  // max-width: 1920px;
}

.header-text {
  margin-bottom: 50px;
}

#ya-karto {
  width: 100%;
  height: 900px;
  position: relative;
}

.input-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 120px;
  background: #fcfcfc;
  width: 400px;
  padding: 60px 50px;
  z-index: 1;
  box-shadow: 0 0 5px #ccc;
}

.logos-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.office-contacts-container {
  margin-top: 50px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logo {
  background: url('../images/logos/logo.png') no-repeat center;
  background-size: contain;
  width: 94px;
  height: 49px;
  cursor: pointer;
  margin-left: 50px;
}

.name-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.dealer {
  font-weight: 500;
  color: #1f6fb6;
  font-size: 24px;
  padding: 0px 7px 0px 0px;
  border-right: 2px solid #1f6fb6;
}

.dealer-text {
  font-weight: 400;
  font-size: 16px;
  color: #1f6fb6;
  padding: 5px 0px;
}

.phone-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 500;
  color: white;
  font-size: 19px;
  height: 30px;
  justify-content: center;
}

.address-worktime-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.office-address {
  font-weight: 400;
  font-size: 16px;
}

.office-worktime {
  font-weight: 400;
  font-size: 16px;
}

.callback-input {
  width: 100%;
}

.link-nav {
  display: inline-block;
  color: #000;
  border-bottom: 1px dashed #000;
  transition: all 0.5s;

  &:hover {
    color: #1f6fb6;
  }
}

.reviews-container {
  width: 455px;
  position: absolute;
  z-index: 1;
  top: 0px;
  right: 0;
  padding: 10px;
  background: rgba(#fff, 0.8);
  box-shadow: 0 0 3px #ccc;
  max-height: 100%;
  overflow-y: auto;
}

.review-wrapper {
  padding: 10px;
  border-bottom: 1px solid #ccc;

  &:last-of-type {
    border-bottom: none;
  }
}

.review-header {
  font-size: 18px;
  font-weight: 500;
}

.review-info-container {
  display: flex;
  flex-direction: row;
  margin: 5px 0;
}

.review-date {
  margin-left: 20px;
  font-size: 14px;
  font-weight: 300;
  color: #9c99a0;
}

.stars-row {
  display: flex;
  flex-direction: row;
}

.star {
  background: url('../images/utils/star.svg') no-repeat center;
  background-size: contain;
  width: 15px;
  height: 15px;
}

.review-text {
  font-size: 15px;
  line-height: 18px;
  height: 55px;
  overflow-y: hidden;

  &.expanded {
    height: auto;
  }
}

.review-expander {
  color: $blue;
  cursor: pointer;
  margin-top: 5px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
