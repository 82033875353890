import { createApp, h } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import Constants from './common/constants';
import components from './components';
import layouts from './layouts';
import './styles/main.scss';
import LazyLoadDirective from './directives/LazyLoadDirective';

import { register } from 'swiper/element/bundle';
register();

import { createGtm } from '@gtm-support/vue-gtm';
window.$ = window.jQuery = require('jquery');
const pinia = createPinia();
const app = createApp({
  render: () => h(App),
  mounted() {
    const req = require.context('./modules', true, /_[^_].*\.(js)$/);
    req.keys().forEach((key) => req(key));
  },
});

app.config.globalProperties.CONSTANTS = Constants;

layouts.forEach((c) => {
  app.component(c.name, c.component);
});

components.forEach((c) => {
  app.component(c.name, c.component);
});
app.use(
  createGtm({
    id: 'GTM-WD7TKC5',
  }),
);
app.directive('lazyload', LazyLoadDirective).use(pinia);
app.mount('#app');
