export default {
  "models": [
    {
      "images": "https://media.ilsa.ru/auto/offers/m/3023/14333/2916782.jpg",
      "img_orientation": 0,
      "model": "Emgrand X7, I Рестайлинг 2",
      "color": "Серебряный",
      "complectation_name": "Комфорт",
      "unique_id": "2916782",
      "price": "1115000",
      "currency": "RUR",
      "modification": "2.0 AT (139 л.с.)",
      "year": "2019",
      "vin": "403124"
    },
    {
      "images": "https://media.ilsa.ru/auto/offers/00/93/776d06b69a51f25131273277c0f76cc9af6c9300.jpeg",
      "img_orientation": 6,
      "model": "Emgrand X7, I Рестайлинг 2",
      "color": "Черный",
      "complectation_name": "Комфорт",
      "unique_id": "2916783",
      "price": "1115000",
      "currency": "RUR",
      "modification": "2.0 AT (139 л.с.)",
      "year": "2019",
      "vin": "403165"
    },
    {
      "images": "https://media.ilsa.ru/auto/offers/m/3023/14336/2965218.jpg",
      "img_orientation": 0,
      "model": "Emgrand X7, I Рестайлинг 2",
      "color": "Серебряный",
      "complectation_name": "Флагшип",
      "unique_id": "2965218",
      "price": "1252900",
      "currency": "RUR",
      "modification": "2.0 AT (139 л.с.)",
      "year": "2019",
      "vin": "403771"
    },
    {
      "images": "https://media.ilsa.ru/auto/offers/89/eb/6ba10dea828d3d066e3c19fce48c1c7780caeb89.jpeg",
      "img_orientation": 1,
      "model": "Atlas, I",
      "color": "Белый",
      "complectation_name": "Comfort",
      "unique_id": "2722185",
      "price": "1365700",
      "currency": "RUR",
      "modification": "2.4 AT (148 л.с.) 4WD",
      "year": "2019",
      "vin": "308468",
    },
    {
      "images": "https://media.ilsa.ru/auto/offers/m/3023/12173/2744680.jpg",
      "img_orientation": 0,
      "model": "Atlas, I",
      "color": "Черный",
      "complectation_name": "Comfort",
      "unique_id": "2744680",
      "price": "1383800",
      "currency": "RUR",
      "modification": "2.4 AT (148 л.с.) 4WD",
      "year": "2019",
      "vin": "308512"
    },
    {
      "images": "https://media.ilsa.ru/auto/offers/m/3023/12174/2916775.jpg",
      "img_orientation": 0,
      "model": "Atlas, I",
      "color": "Серебряный",
      "complectation_name": "Luxury",
      "unique_id": "2916775",
      "price": "1393100",
      "currency": "RUR",
      "modification": "2.4 AT (148 л.с.)",
      "year": "2020",
      "vin": "300140"
    },
    {
      "images": "https://media.ilsa.ru/auto/offers/18/9a/6e220890d286aab98ef5affedec1cf9e33729a18.jpeg",
      "img_orientation": 6,
      "model": "Atlas, I",
      "color": "Белый",
      "complectation_name": "Luxury",
      "unique_id": "2916777",
      "price": "1399300",
      "currency": "RUR",
      "modification": "2.4 AT (148 л.с.)",
      "year": "2020",
      "vin": "300036"
    },
    {
      "images": "https://media.ilsa.ru/auto/offers/m/3023/12174/2931599.jpg",
      "img_orientation": 0,
      "model": "Atlas, I",
      "color": "Зеленый",
      "complectation_name": "Luxury",
      "unique_id": "2931599",
      "price": "1399300",
      "currency": "RUR",
      "modification": "2.4 AT (148 л.с.)",
      "year": "2020",
      "vin": "300224"
    },
    {
      "images": "https://media.ilsa.ru/auto/offers/83/c0/88396a49cdd85b9e07160054337b55a43b23c083.jpeg",
      "img_orientation": 6,
      "model": "Atlas, I",
      "color": "Коричневый",
      "complectation_name": "Luxury",
      "unique_id": "2916780",
      "price": "1483000",
      "currency": "RUR",
      "modification": "2.4 AT (148 л.с.) 4WD",
      "year": "2020",
      "vin": "300622"
    },
    {
      "images": "https://media.ilsa.ru/auto/offers/m/3023/12175/2961036.jpg",
      "img_orientation": 0,
      "model": "Atlas, I",
      "color": "Белый",
      "complectation_name": "Luxury",
      "unique_id": "2961036",
      "price": "1483000",
      "currency": "RUR",
      "modification": "2.4 AT (148 л.с.) 4WD",
      "year": "2020",
      "vin": "302532"
    },
    {
      "images": "https://media.ilsa.ru/auto/offers/m/3023/12175/2961038.jpg",
      "img_orientation": 0,
      "model": "Atlas, I",
      "color": "Черный",
      "complectation_name": "Luxury",
      "unique_id": "2961038",
      "price": "1483000",
      "currency": "RUR",
      "modification": "2.4 AT (148 л.с.) 4WD",
      "year": "2020",
      "vin": "302619"
    },
    {
      "images": "https://media.ilsa.ru/auto/offers/m/3023/12175/2965217.jpg",
      "img_orientation": 0,
      "model": "Atlas, I",
      "color": "Серебряный",
      "complectation_name": "Luxury",
      "unique_id": "2965217",
      "price": "1483000",
      "currency": "RUR",
      "modification": "2.4 AT (148 л.с.) 4WD",
      "year": "2020",
      "vin": "302014"
    },
    {
      "images": "https://media.ilsa.ru/auto/offers/e8/67/2e5d9f628a2a0a8e03e941af352c1eb3258767e8.jpeg",
      "img_orientation": 1,
      "model": "Atlas, I",
      "color": "Белый",
      "complectation_name": "Комфорт",
      "unique_id": "2594460",
      "price": "1390300",
      "currency": "RUR",
      "modification": "1.8 AT (184 л.с.)",
      "year": "2019",
      "vin": "305585"
    },
    {
      "images": "https://media.ilsa.ru/auto/offers/m/3023/14671/2916772.jpg",
      "img_orientation": 0,
      "model": "Atlas, I",
      "color": "Бежевый",
      "complectation_name": "Люкс",
      "unique_id": "2916772",
      "price": "1579100",
      "currency": "RUR",
      "modification": "1.8 AT (184 л.с.)",
      "year": "2019",
      "vin": "305429"
    },
    {
      "images": "https://media.ilsa.ru/auto/offers/m/3023/14671/2916773.jpg",
      "img_orientation": 0,
      "model": "Atlas, I",
      "color": "Зеленый",
      "complectation_name": "Люкс",
      "unique_id": "2916773",
      "price": "1579100",
      "currency": "RUR",
      "modification": "1.8 AT (184 л.с.)",
      "year": "2019",
      "vin": "308076"
    },
    {
      "images": "https://media.ilsa.ru/auto/offers/2e/4e/1fe7201703bc62ebf66b8c4493ff53ee08264e2e.jpeg",
      "img_orientation": 1,
      "model": "Atlas, I",
      "color": "Коричневый",
      "complectation_name": "Люкс",
      "unique_id": "2837501",
      "price": "1284100",
      "currency": "RUR",
      "modification": "2.0 MT (139 л.с.)",
      "year": "2019",
      "vin": "300319"
    },
    {
      "images": "https://media.ilsa.ru/auto/offers/2f/1b/e66444fcbbccfe312a1795bcc1519c1c7b591b2f.jpeg",
      "img_orientation": 1,
      "model": "Atlas, I",
      "color": "Зеленый",
      "complectation_name": "Люкс",
      "unique_id": "2837502",
      "price": "1284100",
      "currency": "RUR",
      "modification": "2.0 MT (139 л.с.)",
      "year": "2019",
      "vin": "300337"
    },
    {
      "images": "https://media.ilsa.ru/auto/offers/m/3023/15522/2961029.jpg",
      "img_orientation": 0,
      "model": "Atlas, I",
      "color": "Черный",
      "complectation_name": "Люкс с Яндекс.Авто",
      "unique_id": "2961029",
      "price": "1643900",
      "currency": "RUR",
      "modification": "1.8 AT (184 л.с.) 4WD",
      "year": "2020",
      "vin": "301109"
    },
    {
      "images": "https://media.ilsa.ru/auto/offers/m/3023/16721/2986020.jpg",
      "img_orientation": 0,
      "model": "GS, I",
      "color": "Красный",
      "complectation_name": "Luxury",
      "unique_id": "2986020",
      "price": "1379990",
      "currency": "RUR",
      "modification": "1.8 AMT (133 л.с.)",
      "year": "2019",
      "vin": "070934"
    },
    {
      "images": "https://media.ilsa.ru/auto/offers/m/3023/16722/2986809.jpg",
      "img_orientation": 0,
      "model": "Coolray, I",
      "color": "Оранжевый",
      "complectation_name": "Флагшип",
      "unique_id": "2986809",
      "price": "1388700",
      "currency": "RUR",
      "modification": "1.5 AMT (150 л.с.)",
      "year": "2020",
      "vin": "900346"
    }
  ]
}