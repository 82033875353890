export const wideTimer = () => {
  var _id = '919ce9a1b4e8395692538b802604874c';
  while (document.getElementById('timer')) _id = _id + '0';
  var _t = document.createElement('script');
  _t.src = '//megatimer.ru/timer/timer.min.js?v=1';
  var _f = function (_k) {
    var l = new MegaTimer(_id, {
      view: [1, 1, 1, 1],
      type: {
        currentType: '3',
        params: {
          weekdays: [1, 0, 0, 0, 0, 0, 0],
          usertime: true,
          time: '00:00',
          tz: -180,
          hours: '168',
          minutes: '',
        },
      },
      design: {
        type: 'circle',
        params: {
          width: '4',
          radius: '52',
          line: 'solid',
          'line-color': '#002E5D',
          background: 'solid',
          'background-color': 'rgba(0,0,0,.07)',
          direction: 'direct',
          'number-font-family': {
            family: 'Open Sans',
            link: "<link href='//fonts.googleapis.com/css?family=Open+Sans&subset=latin,cyrillic' rel='stylesheet' type='text/css'>",
          },
          'number-font-size': '40',
          'number-font-color': '#000',
          'separator-margin': '16',
          'separator-on': false,
          'separator-text': ':',
          'text-on': true,
          'text-font-family': {
            family: 'Open Sans',
            link: "<link href='//fonts.googleapis.com/css?family=Open+Sans&subset=latin,cyrillic' rel='stylesheet' type='text/css'>",
          },
          'text-font-size': '16',
          'text-font-color': '#000',
        },
      },
      designId: 7,
      theme: 'black',
      width: 552,
      height: 114,
    });
    if (_k != null) l.run();
  };
  _t.onload = _f;
  _t.onreadystatechange = function () {
    if (_t.readyState == 'loaded') _f(1);
  };
  var _h = document.head || document.getElementsByTagName('head')[0];
  _h.appendChild(_t);
};
