<template>
  <section class="bg-primary-blue" id="testdrive">
    <div class="container py-5 lg:py-14">
      <div class="content flex flex-wrap items-center justify-between">
        <div class="video_block mb-10 w-full">
          <img
            class="h-auto w-full object-cover md:h-[580px]"
            src="../images/credit/test-drive-main.jpg"
            alt="Пройдите тест-драйв"
          />
        </div>
        <div class="container flex flex-wrap items-center justify-between">
          <div class="main_block md:w-1/2">
            <div class="pb-5 uppercase text-white md:text-2xl lg:text-3xl">
              Пройдите тест-драйв
            </div>
            <div class="desc text-white">
              ВЕСЬ МОДЕЛЬНЫЙ РЯД GEELY в наличии на тест-драйве!<br />
              Запишитесь сейчас и получите бонус при покупке
            </div>
          </div>
          <vee-form
            :validation-schema="schema"
            novalidate
            class="flex w-full flex-col space-y-6 md:w-1/2 md:gap-6 md:space-y-0"
            @submit="onSubmit"
          >
            <div class="form_item w-full">
              <vee-field v-slot="{ handleChange, errorMessage }" name="phone">
                <v-input
                  v-model="phone"
                  placeholder="+7 (___) ___-__-__"
                  theme="white"
                  format="phone"
                  :error="errorMessage"
                  @update:modelValue="handleChange"
                />
              </vee-field>
            </div>
            <button
              class="w-full btn-blue relative mx-auto flex gap-x-2 after:h-4 after:w-4 after:-rotate-45 after:border-b-2 after:border-r-2 after:border-white after:content-['']"
              type="submit"
              name="button"
            >
              <span class="btn__text btn__text_theme_dark"
                >Записаться на тест-драйв</span
              >
            </button>
          </vee-form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Field, Form } from 'vee-validate';
import * as Yup from 'yup';
import VInput from './common/VInput.vue';
import emitter from 'tiny-emitter/instance';
export default {
  name: 'block-testdrive',
  data() {
    return {
      phone: null,
    };
  },
  setup() {
    const schema = Yup.object().shape({
      phone: Yup.string().required(),
    });
    return {
      schema,
    };
  },
  props: {},

  components: {
    VInput,

    VeeForm: Form,
    VeeField: Field,
  },

  created() {},

  mounted() {},

  beforeDestroy() {},

  methods: {
    onSubmit(value, actions) {
      emitter.emit('callBack', {
        phone: this.phone,
        data: {
          type: 'test-drive',
          form: 'test-drive',
        },
      });

      this.phone = null;
      setTimeout(() => {
        actions.resetForm();
      }, 1000);
    },
  },

  computed: {},

  watch: {},

  directives: {},

  filters: {},
};
</script>

<style scoped lang="scss"></style>
