<template>
  <div class="flex items-center hover:bg-gray-100 cursor-pointer" @click="onClick">
    <div class="relative flex-shrink-0 w-5 h-5 rounded-[5px] bg-gray-200 mr-2">
      <svg-check
        class="abs-center w-4 text-primary-blue fill-current"
        :class="{ hidden: !$data._value }"
      />
    </div>
    <slot></slot>
  </div>
</template>
<script>
import SvgCheck from '../Svg/Check';

export default {
  name: 'Checkbox',
  components: {
    SvgCheck,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    clickEvent: {
      type: Boolean,
      default: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      _value: false,
    };
  },
  watch: {
    checked(newValue) {
      this.$data._value = newValue;
    },
    modelValue(newValue) {
      this.$data._value = newValue;
    },
  },
  created() {
    this.$data._value = this.checked;
  },
  methods: {
    onClick() {
      this.$emit('on-click');
      if (this.clickEvent) {
        this.$data._value = !this.$data._value;
        this.$emit('input', this.$data._value);
      }
    },
  },
};
</script>
<style scoped>
.abs-center {
  @apply absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2;
}
</style>