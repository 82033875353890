<script>
export default {
  mounted() {
  console.log(document.querySelector("#timer-item"))
  setTimeout(() => {
      

    (function () {
      var _id = "16b1ad89edff870040cee113de99266c";
      while (document.getElementById("timer" + _id)) _id = _id + "0";
      
      document.querySelector("#timer-item").appendChild(
        "<div id='timer" + _id + "' style='min-width:373px;height:60px;'></div>"
      );
      var _t = document.createElement("script");
       
      _t.src = "timer.min.js?v=1";
      var _f = function (_k) {
        var l = new MegaTimer(_id, {
          view: [1, 1, 1, 1],
          type: {
            currentType: "3",
            params: {
              weekdays: [1, 1, 1, 1, 1, 1, 1],
              usertime: true,
              time: "00:00",
              tz: -180,
              hours: "23",
              minutes: "59",
            },
          },
          design: {
            type: "text",
            params: {
              "number-font-family": {
                family: "Open Sans",
                link: "<link href='//fonts.googleapis.com/css?family=Open+Sans&subset=latin,cyrillic' rel='stylesheet' type='text/css'>",
              },
              "number-font-size": "60",
              "number-font-color": "#d30043",
              "separator-margin": "20",
              "separator-on": true,
              "separator-text": ":",
              "text-on": false,
              "text-font-family": {
                family: "Comfortaa",
                link: "<link href='//fonts.googleapis.com/css?family=Comfortaa&subset=latin,cyrillic' rel='stylesheet' type='text/css'>",
              },
              "text-font-size": "12",
              "text-font-color": "#c7c7c7",
            },
          },
          designId: 1,
          theme: "white",
          width: 373,
          height: 60,
        });
        if (_k != null) l.run();
      };
      _t.onload = _f;
      _t.onreadystatechange = function () {
        if (_t.readyState == "loaded") _f(1);
      };
      document.write(_t);  
    //   var _h = document.head || document.getElementsByTagName("head")[0];
    //   _h.appendChild(_t);
    }.call(this));  }, 1);
  },
};
</script>
<template>
<div id="timer-item"></div>
</template>