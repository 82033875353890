const context = require.context('./components', true, /^[^_]((?!\/_).)*\.vue$/);

export default context.keys().map(pathToModule => {
  let name = pathToModule
    .substr(2, pathToModule.length - 6)
    .split(/(?=[A-Z])/)
    .join('-')
    .split('/')
    .join('-')
    .toLowerCase();

  return {
    name,
    component: context(pathToModule).default,
  };
});