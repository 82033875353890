<template>
  <div class="w-full">
    <div class="bg-white z-10">
      <div class="container p-[8%] relative">
        <div
          class="cursor-pointer absolute top-4 right-0 w-8 h-8 before:content-[''] before:absolute before:w-8 before:h-px before:bg-black before:rotate-45 after:content-[''] after:absolute after:w-8 after:h-px after:bg-black after:-rotate-45 z-30"
          @click="modalStore.hideModal"
        ></div>
        <h2 
          class="text-black font-semibold text-3xl mb-4 text-center" 
          v-html="modalTitle"
        ></h2>
        <callback-form-callback-input 
          class="mt-10"
          :data="{ type: 'sale', form: 'offer', placeholder: 'Контактный телефон' }" 
        >
            {{ modalButton ?? 'ОСТАВИТЬ ЗАЯВКУ'}}
          </callback-form-callback-input>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useModalStore } from "@/stores/modalStore";
import { storeToRefs } from 'pinia'

const modalStore = useModalStore()
const { modalTitle, modalButton } = storeToRefs(modalStore)
</script>
