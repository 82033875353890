import { ref } from "vue";
import { defineStore } from "pinia";
import { useMessageStore } from "./messageStore";

export const useModalStore = defineStore("modals", () => {
  const message = useMessageStore();
  const modalVisible = ref(false);
  const modalComponent = ref(null);
  const modalTitle = ref(null);
  const modalButton = ref(null);
  const modalMessage = ref(null);

  const showModal = (payload) => {
    message.getMessageType(payload);
    modalVisible.value = true;
    modalComponent.value = payload.component;
    document.body.style.overflow = "hidden";
    modalTitle.value = payload.title;
    modalButton.value = payload.button;
    if (message.messageType == "agreement") {
      message.getAllAgreement();
      modalMessage.value = message.all;
    }
    if (message.messageType == "conditions") {
      message.getAllConditions();
      modalMessage.value = message.all;
    }
  };

  const hideModal = () => {
    if (!document.querySelector(".mobile-menu").classList.contains("open")) {
      document.body.style.overflow = "auto";
    }
    $reset();
    message.$reset();
  };

  function $reset() {
    modalVisible.value = false;
    modalComponent.value = null;
    modalTitle.value = null;
    modalButton.value = null;
    modalMessage.value = null;
  }

  return {
    modalVisible,
    modalComponent,
    modalButton,
    modalTitle,
    modalMessage,
    $reset,
    showModal,
    hideModal,
  };
});
