<template>
    <div>
        <div class="blacking"
             @click="hideSelf"></div>
        <div class="agreement-container">
            <div class="close"
                 @click="hideSelf">X
            </div>
            <div class="agreement-text">
                <div class="agreement-header">
                    СОГЛАСИЕ<br>
                    на обработку персональных данных<br>
                    (далее – «Согласие»)

                </div>
                <div class="agreement-part">
                    Geely - Кунцево охраняет конфиденциальность личной информации каждого посетителя наших веб-сайтов.
                    Собранная информация о вас будет использована прежде всего для удовлетворения ваших просьб, а также
                    улучшения наших продуктов и услуг. При этом мы используем информацию подходящим способом. Собранная
                    информация не будет предоставляться третьим лицам или другим партнерам, обслуживающим вас от нашего
                    имени. Информация будет использована с соблюдением действующего законодательства в области защиты
                    личных сведений. Вы имеете право знать, какая информация о вас собрана нами и при необходимости
                    исправить ее, а также попросить нас не использовать эту информацию. Если у вас возникнут вопросы,
                    пожалуйста, отправьте их по адресу электронной почты info@kuntsevo.com Компания, обеспечивающая
                    исполнение договоров по сервисам Geely - Кунцево и / или имеющие доступ к персональным данным ООО
                    "Кунцево Авто" Юридический адрес: Московская обл., Одинцовский район, с.Немчиновка, ул.Московская,
                    д.61 Официальный дилер легковых автомобилей Geely, оператор базы данных Для направления заявлений вы
                    можете отправить письменный запрос на email: info@kuntsevo.com или воспользоваться формой обратной
                    связи.
                </div>

            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: '',
        props: ['type'],
        methods: {
            hideSelf() {
                this.$emit('close');
            }
        }
    }
</script>

<style scoped lang="scss">
    .blacking {
        position: fixed;
        z-index: 500;
        top: -100vh;
        left: -100vw;
        bottom: -100vw;
        right: -100vh;
        background: #000;
        opacity: 0.5;
        cursor: default;
    }

    .agreement-container {
        top: 30px;
        height: calc(100vh - 90px);
        left: 15px;
        width: calc(100vw - 30px);
        position: fixed;
        z-index: 1200;
        background: #fff;
        padding: 40px 20px;
        cursor: default;
        color: #000;
    }

    .close {
        position: absolute;
        font-size: 20px;
        top: 10px;
        right: 15px;
        cursor: pointer;
    }

    .agreement-header {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    .agreement-part {
        margin-bottom: 10px;
    }

    .agreement-text {
        font-size: 14px;
        overflow: auto;
        max-height: 100%;
    }
</style>
