<template>
  <div class="relative overflow-hidden bg-primary-blue" id="trade-in">
    <div class="container py-5 lg:py-14">
      <div class="py-12 px-0">
        <div class="my-0 mx-auto max-w-7xl py-0 px-4">
          <div class="text-white mb-4 text-xl font-bold text-center leading-tight uppercase md:text-3xl xl:text-4xl">
            GEELY <span>с выгодой</span> в трейд‑ин
          </div>
          <div class="text-white m-[0_auto_1.5rem] max-w-3xl leading-tight opacity-60 text-center md:text-2xl xl:mb-11">
            Оставьте заявку, чтобы узнать рыночную стоимость вашего автомобиля,
            а также бонус, который вы получите при покупке GEELY в трейд‑ин
          </div>
          <vee-form
            :validation-schema="schema"
            novalidate
            class="flex justify-center flex-wrap my-0 -mx-4"
            @submit="onSubmit"
          >
            <div class="flex py-0 px-4 mb-6 w-80">
              <vee-field v-slot="{ handleChange, errorMessage }" name="phone">
                <v-input
                  class="w-full"
                  v-model="phone"
                  placeholder="+7 (___) ___-__-__"
                  theme="white"
                  format="phone"
                  :error="errorMessage"
                  @update:modelValue="handleChange"
                />
              </vee-field>
            </div>
            <div class="flex py-0 px-4 mb-6 w-80">
              <button class="flex justify-center w-full items-center h-12 text-white border-solid border-white border relative gap-x-2 after:h-4 after:w-4 after:-rotate-45 after:border-b-2 after:border-r-2 after:border-white after:content-['']" type="submit">
                <span>
                  ПОЛУЧИТЬ ПРЕДЛОЖЕНИЕ
                </span>
              </button>
            </div>
          </vee-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form } from 'vee-validate';
import * as Yup from 'yup';
import VInput from './common/VInput.vue';
import emitter from 'tiny-emitter/instance';

export default {
  name: 'trade-in',
  data() {
    return {
      phone: null,
    };
  },
  setup() {
    const schema = Yup.object().shape({
      phone: Yup.string().required(),
    });
    return {
      schema,
    };
  },
  props: {},

  components: {
    VInput,
    VeeForm: Form,
    VeeField: Field,
  },

  created() {},

  mounted() {},

  beforeDestroy() {},

  methods: {
    onSubmit(value, actions) {
      emitter.emit('callBack', {
        phone: this.phone,
        data: {
          type: 'trade-in',
          form: 'trade-in'
        },
      });

      this.phone = null;
      setTimeout(() => {
        actions.resetForm();
      }, 1000);
    },
  },

  computed: {},

  watch: {},

  directives: {},

  filters: {},
};
</script>