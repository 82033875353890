<template>
  <button
    :class="classNames"
    @click="
      modalStore.showModal({
        component: 'ModalCallback',
        title: modalTitle,
        button: modalButton
      })
    "
  >
    <slot/>
  </button>
</template>
<script setup>
import { useModalStore } from "@/stores/modalStore";

const props = defineProps({
  classNames: {
    type: String,
    default: "",
  },
  modalTitle: {
    type: String,
    default: "Оставить заявку",
  },
  modalButton: {
    type: String,
    default: "Отправить",
  }
});

const modalStore = useModalStore();
</script>