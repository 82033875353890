<script>
import Timer from './timer.vue'
export default{
components:{
    Timer
},
    setup() {
        
    },
    mounted() {
        // let Script = document.createElement("script");
        // Script.setAttribute("src", "https://megatimer.ru/get/16b1ad89edff870040cee113de99266c.js");
        // var element = document.getElementById("timer-item")
        // element.appendChild(Script);      
    },
    data() {
        return {
            phone:'',
        }
    }
}
</script>


<template>
    <div class="container">
        <div class="timer-container">
        
            <div class="timer-item text">
                <span>До конца акции осталось: </span>
            </div>
            <div class="timer-item timer">
                <Timer/>
            </div>
            <div class="form-container">
                <div>
                    <div class="timer-item">
                        <input placeholder="Ваш телефон" class="phone"/>
                        <button>Получить предложение</button>
                    </div>
                </div>
                <div class="approvement-item">
                  <input type="checkbox" value="approvement" checked>
                  <label for="approvement">Согласие на <span>обработку персональных данных</span></label>
                </div>
            </div>
        </div>
    </div>

</template>
<style scoped>
.timer-container {
    display: flex;
    width: 100%;
    background-color: #3A3C3B;
    padding: 15px;
    align-items: center;
}
.text {
    color: white;
    font-weight: 700;
}
button {
  background-color: #D30043;
  color:white;
  padding: 15px;
  margin-left: 20px;
  height: 50px;
  border-radius: 5px;
}
.phone {
padding: 15px;
margin-left: 20px;
height: 50px;
border-radius: 5px;
}
.approvement-item {
    display: flex;
    flex-flow: row;
    padding-top: 15px;
}
.approvement-item span {
    border-bottom: rgb(0, 0, 136) 2px dashed;
}
.approvement-item input {
    margin-left: 20px;

}
label {
    margin-left: 20px;
    color: white
}
</style>