<template>
  <section>
    <div class="">
      <div
        class="container flex flex-col-reverse justify-between py-12"
      >
        <div class="flex flex-col items-center">
          <h2 class="text-center text-xl uppercase text-black">
            Успейте получить выгодное предложение
          </h2>
          <callback-form-callback-input
            :data="{ form: 'timer', type: 'sale' }"
            input_class="flex lg:!flex-row gap-x-4"
            class="mt-[40px]"
          >
            ОСТАВИТЬ ЗАЯВКУ
          </callback-form-callback-input>
        </div>
        <div
          class="min-[1080px]:ml-[30px]"
        >
          <div
            class="timer min-w-[552px] md:h-full origin-[17%_0] !scale-50 max-[595px]:max-w-[290px] md:origin-[45%_0] md:!scale-[.8] xl:origin-center xl:scale-0 max-md:relative max-md:left-[calc(50%-184px)]"
          >
            <div
              id="timer919ce9a1b4e8395692538b802604874c"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { wideTimer } from '@/helpers/megatimer';
import CallbackInput from './callback-form/callback-input';
export default {
  name: 'block-timer',
  components: {
    CallbackInput,
  },
  mounted() {
    wideTimer();
  },
};
</script>
