<template>
    <div class="selectors-bar"
         :class="[device_platform]">
        <div class="selector"
             v-for="selector in values"
             :id="'sel-' + selector.name.toLowerCase()"
             :class="{active: selector.name === cur_value}"
             @click="selectModel(selector.name)">
            {{
                selector.text ? selector.text
                : selector.name == 'X50' ? 'Belgee ' + selector.name
                : selector.name === 'Atlas, II' ? 'New Atlas'
                : selector.name === 'Coolray, I' ? 'Coolray'
                : selector.name === 'Coolray, I Рестайлинг' ? 'NEW Coolray'
                : selector.name === 'Emgrand, II' ? 'Emgrand'
                : selector.name === 'Tugella, I Рестайлинг' ? 'Tugella'
                : selector.name === 'Okavango, I Рестайлинг' ? 'Okavango'
                : selector.name
            }}
        </div>
    </div>
</template>

<script>
    import Mixin from "../common/mixin";

    export default {
        name: 'selectorBar',
        props: ['values', 'cur_value', 'b_cars_car'],
        mixins: [Mixin],
        data() {
            return {
                bg_width: 0,
                bg_height: 0,
                bg_prev_left: 0,
                bg_offset: {
                    left: 0,
                    top: 0
                }
            }
        },
        mounted() {
            window.addEventListener('resize', this.getBgSize);
            this.$nextTick(() => {
                this.getBgSize();
            });
        },
        watch: {
            b_cars_car(value) {
                this.selectModel(value.name);
            }
        },
        computed: {
            c_bg_position() {
                const transform = this.device_platform === 'mobile' ?
                    'translateY(' + this.bg_offset.top + 'px)' :
                    'translateX(' + this.bg_offset.left + 'px)';
                return {
                    width: this.bg_width + 'px',
                    height: this.bg_height + 'px',
                    top: 0,
                    transform: transform
                };
            }
        },
        methods: {
            getBgSize() {
                let el = document.getElementById('sel-' + this.cur_value.toLowerCase());
                this.bg_width = el && el.offsetWidth;
                this.bg_height = el && el.offsetHeight;
                this.bg_prev_left = this.bg_offset.left;
                this.bg_offset = {
                    left: el?.offsetLeft,
                    top: el?.offsetTop
                };
            },
            selectModel(name) {
                this.$emit('selectModel', name);
                this.$nextTick(() => {
                    this.getBgSize();
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../styles/constants.scss";
    .desktop {
        .selectors-bar {
            flex-direction: row;
            overflow-x: auto;
            padding: 20px 0;
            gap: 10px;
        }
        .selector {
            @apply min-w-[140px] py-[16px] px-[20px] uppercase justify-center text-[15px] whitespace-nowrap
        }
    }
    .tablet {
        .selectors-bar {
            flex-direction: row;
            overflow-x: auto;
            padding: 20px 0;
            gap: 10px;
        }
        .selector {
            font-size: 15px;
            @apply min-w-[140px] py-[16px] px-[20px] uppercase justify-center text-[15px] whitespace-nowrap;
        }
    }

    .mobile {
        .selectors-bar {
            flex-direction: row;
            overflow-x: scroll;
            padding: 20px 0;
            gap: 10px;
        }

        .selector {
            width: 100%;
            @apply min-w-[140px] py-[16px] px-[20px] uppercase justify-center text-[15px] whitespace-nowrap;
            max-width: 100%;
        }
    }

    .selectors-bar {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        gap: 20px
    }

    .selector {
        max-width: 340px;
        // height: 85px;
        display: flex;
        align-items: flex-end;
        font-weight: 500;
        text-align: center;
        font-size: 15px;
        cursor: pointer;
        position: relative;
        transition: color .2s;
        color: #000;
        border: 1px solid #002E5D;
        @apply xl:py-[21px] xl:px-[26px] xl:w-[160px] uppercase justify-center;
        &.active {
            font-weight: 500;
            @apply text-white bg-[#002E5D]
        }

        &:nth-child(n+3) {
            text-transform: uppercase;
        }


        &:last-child {
            &:after {
                display: none;
            }
        }
    }


</style>
