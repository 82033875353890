<template>
  <figure v-lazyload class="flex shrink-0 max-w-full justify-center items-center rounded group">
    <ImageSpinner class="group-[&.loaded]:hidden group-[&.loaded]:w-full" />
    <img
      :alt="altName"
      :data-url="source"
      class="w-full ease-in-out duration-300 z-0 [&.active_img]:z-10 group-[&.loaded]:visible group-[&.loaded]:opacity-100 group-[&.loaded]:border-0 rounded opacity-0 invisible"
      :class="classes"
    />
  </figure>
</template>
<script>
import ImageSpinner from "./Image-Spinner.vue";

export default {
  name: "image-item",
  props: {
    source: {
      type: String,
      required: true,
    },
    altName: {
      type: String,
      required: true,
    },
    idx: Number,
    modelName: String,
    classes: [String, Array],
  },
  components: { ImageSpinner }
}
</script>