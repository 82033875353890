<template>
    <header 
      class="sticky left-0 top-0 z-30 w-full bg-white shadow-lg duration-300 ease-in-out"
      id="header"
    >
      <div class="container max-lg:h-[80px] max-lg:flex max-lg:flex-col max-lg:justify-center">
        <div class="xl:relative flex justify-between items-center h-16 xl:h-20 lg:border-b lg:border-black">
          <div class="hidden md:flex">
            <div class="pr-4 text-black uppercase border-r border-black mr-4">АТЦ КУНЦЕВО<br>
            Официальный дилер</div>
            <!-- <div class="text-black">Официальный дилер</div> -->
            <div class="dealer-logo max-w-[240px] my-auto">
              <img src="../images/logos/kuncevo_logo_rus.png">
            </div>
          </div>
          <!-- <svg-logo class="xl:absolute xl:left-1/2 xl:-translate-x-1/2 xl:top-1/2 xl:-translate-y-1/2 w-56 h-14 text-black"></svg-logo> -->
          <!-- <div class="max-w-[300px]"> -->
            <img class="max-w-[200px]" src="../images/logos/logo_minimal.png">
          <!-- </div> -->
          <!-- <a :href="'tel:' + office.phone_raw"
            class="link link-phone hidden lg:flex text-black hover:opacity-80 transition duration-200 ease-in-out">
            <span class="xl:text-[28px]">{{ office.phone }}</span>
          </a> -->
          <buttons--button-modal-caller
            modal-title="Заказать звонок"
            modal-button="ЗАКАЗАТЬ"
            class="link link-phone hidden lg:flex text-black hover:opacity-80 transition duration-200 ease-in-out"
            >
              <span class="xl:text-[28px]">{{ office.phone }}</span>
          </buttons--button-modal-caller>
          <div class="block w-[22px] h-[22px] md:hidden">
              <a :href="'tel:' + office.phone_raw"><img src="../images/utils/telephone.png" alt="Phone"></a>
          </div>
          <!-- <button class="burger menu-trigger ml-7 lg:hidden"><span></span></button> -->
          <button class="burger menu-trigger group sm:ml-[25px] lg:hidden">
            <img src="../images/menu/menu.png" alt="menu" />
          </button>
        </div>
        <div class="hidden lg:flex justify-between items-center h-16 xl:h-20">
          <div class="flex flex-row">
            <button data-href="cars"
              class="anchor text-black uppercase mr-8 xl:mr-12 hover:opacity-80 transition duration-200 ease-in-out">
              МОДЕЛЬНЫЙ РЯД
            </button>
            <button data-href="configurator"
              class="anchor text-black uppercase mr-8 xl:mr-12 hover:opacity-80 transition duration-200 ease-in-out">
              КОНФИГУРАТОР
            </button>
            <button data-href="in_stock"
              class="anchor flex text-black uppercase mr-8 xl:mr-12 hover:opacity-80 transition duration-200 ease-in-out">
              GEELY В НАЛИЧИИ
            </button>
          </div>
          <div class="flex">
            <buttons--button-modal-caller
              modal-title="РАССЧИТАТЬ КРЕДИТ"
              modal-button="РАССЧИТАТЬ"
              class="flex text-black uppercase hover:opacity-80 transition duration-200 ease-in-out anchor mr-8 xl:mr-12"
            >
             <svg-recall class="w-5 h-5 text-gray-900 mr-3"></svg-recall>
              РАССЧИТАТЬ КРЕДИТ
            </buttons--button-modal-caller>
            <button data-href="contacts"
              class="flex text-black uppercase hover:opacity-80 transition duration-200 ease-in-out anchor ">
              <svg-location class="w-5 h-5 text-gray-900 mr-3"></svg-location>
              Контакты
            </button>
            
          </div>
        </div>
      </div>
    </header>
    
    <div class="mobile-menu">
      <div
        class="flex h-[90px] flex-col items-center justify-center border-b border-[#100F26]/10 px-[9%] lg:h-[87px] xl:h-20"
      >
        <div class="flex w-full items-center justify-between">
          <img
            src="../images/logos/logo_minimal.png"
            alt="logo"
            class="mr-auto max-w-[180px]"
          />
          <buttons--button-modal-caller
            modal-title="РАССЧИТАТЬ КРЕДИТ"
            modal-button="РАССЧИТАТЬ"
            class="hidden h-[50px] cursor-pointer bg-[#002E5D] text-[12px] text-white sm:flex sm:px-[22px] sm:py-[15px] sm:leading-[19px] md:text-[16px]"
          >
            РАССЧИТАТЬ КРЕДИТ
          </buttons--button-modal-caller>
          <button class="burger menu-trigger sm:ml-[25px]">
            <img src="../images/menu/close.png" alt="menu" />
          </button>
        </div>
      </div>
      <div class="flex w-full flex-col px-[9%] lg:mt-[60px]">
        <div class="mt-[40px] md:mt-[90px]">
          <p
            data-href="cars"
            class="cursor-pointer text-[16px] font-medium uppercase leading-[20px]"
          >
            модельный ряд
          </p>
        </div>
        <div class="mt-[40px] md:mt-[50px]">
          <p
            data-href="configurator"
            class="cursor-pointer text-[16px] font-medium uppercase leading-[20px]"
          >
            конфигуратор
          </p>
        </div>
        <div class="mt-[40px] md:mt-[50px]">
          <p
            data-href="in_stock"
            class="cursor-pointer text-[16px] font-medium uppercase leading-[20px]"
          >
            Geely в наличии
          </p>
        </div>
        <div class="mt-[40px] md:mt-[50px]">
          <p
            data-href="contacts"
            class="cursor-pointer text-[16px] font-medium uppercase leading-[20px]"
          >
            контакты
          </p>
        </div>
      </div>
      <buttons--button-modal-caller
        modal-title="ЗАКАЗАТЬ ЗВОНОК"
        modal-button="ЗАКАЗАТЬ"
        class="mt-auto w-full bg-[#002E5D] py-[17px] text-center uppercase text-white"
      >
        ЗАКАЗАТЬ ЗВОНОК
      </buttons--button-modal-caller>
    </div>
</template>
<script>
import Mixin from '../common/mixin';
import $ from 'jquery';
import emitter from 'tiny-emitter/instance';
import SvgLocation from '../utils/Location.vue';
import SvgRecall from '../utils/Recall.vue';

export default {
  name: 'block-header',
  components: {
    SvgLocation,
    SvgRecall
  },
  directives: {},
  mixins: [Mixin],
  emits: ['getAgreement', 'scrollTo', 'callBack', 'getCall', 'bCarsClicked'],
  data() {
    return {
      office: this.CONSTANTS.office,
    };
  },
  mounted() {
    // $("html").on("keyup", (event) => {
    // if (event.keyCode === 27) {
    // this.show.creq = false;
    // }
    // });
  },
  methods: {
    creditpage(url) {
      window.open(url, '_blank').focus();
    },
    scrollTo(where, delta) {
      let newhash = '#' + where;
      history.replaceState(null, null, newhash);

      var delta = 0;
      switch (where) {
        case 'selector':
          if (this.device_platform === 'desktop') {
            if ($(window).width() < 1607) delta = 270;
            else delta = 200;
          } else delta = 130;

          break;
        case 'cars-wrapper':
          if (this.device_platform === 'desktop') {
            if ($(window).width() < 1607) delta = 270;
            else delta = 200;
          } else delta = 130;
          break;
        case 'choice':
          if (this.device_platform === 'desktop') {
            if ($(window).width() < 1607) delta = 130;
            else delta = 50;
          } else {
            delta = -10;
          }
          break;
        case 'contacts':
          break;
        default:
          break;
      }
      emitter.emit('scrollTo', where, delta);
    },
    getCall(data) {
      emitter.emit('getCall', data);
    },
  },
};
</script>

<style scoped lang="scss">
@import '../styles/constants.scss';
.tablet {
  &.block-header-container {
    padding-top: 0px;
    height: auto;
  }

  .content-container {
    height: 140px;
  }

  .header-menu {
    height: 70px;
  }

  .name-container {
    order: 0;
  }

  .buttons-container {
    order: 1;
  }

  .address-worktime-container {
    order: 2;
  }

  // .logo {
  // order: 3;
  // }

  .phone-container {
    order: 4;
  }

  .menu-item {
    font-size: 18px;
    /*margin: 0 30px;*/
    padding: 0 32px;
  }
}

.mobile {
  &.block-header-container {
    padding-top: 0px;
    height: auto;
  }

  .content-container {
    flex-direction: column;
    height: auto;
  }

  .logo {
    width: 70px;
    height: 42px;
    order: 1;
    margin: 0 0 5px;
  }

  .name-container {
    order: 0;
    flex-direction: column;
    align-items: center;
    margin: 15px 0px 0px;
  }

  .dealer {
    font-size: 24px;
    text-align: center;
  }

  .dealer-text {
    font-size: 16px;
  }

  .buttons-container {
    order: 2;
    margin: 0px 0px 15px;
  }

  .phone-container {
    order: 3;
    margin: 0px 0px 10px;
    font-size: 18px;
  }

  .office-address {
    margin-bottom: 0;
  }

  .address-worktime-container {
    order: 4;
    margin: 0px 0px 15px;
  }
  .header-menu {
    flex-wrap: wrap;
  }
  .menu-item {
    width: 50%;
    height: 50%;
    border-bottom: 1px solid #ccc;
    text-align: center;
    padding: 0 10px;
  }
}

.office-address-container {
  display: flex;
  align-items: center;
}

.office-address-icon {
  margin-right: 15px;
}

.office-phone {
  display: flex;
  align-items: center;
}

.office-phone-icon {
  margin-right: 15px;
}

.office-phone-link {
  font-weight: 500;
}

.block-header-container {
  position: fixed;
  top: 0px;
  width: 100%;
  background: #fff;
  z-index: 20;
  height: auto;
}

.content-container {
  width: 100%;
  margin: auto;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1f6fb6;
  flex-wrap: wrap;
  top: 0;
  left: 0;
  z-index: 5;
}

.header-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  cursor: pointer;
  z-index: 5;
}

.menu-item {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 20px;
  padding: 0 40px;
  border-right: 1px solid #ccc;
  &:first-of-type {
    border-left: 1px solid #ccc;
  }
}

.name-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;
}

.dealer {
  font-weight: 400;
  font-size: 24px;
  color: white;
  padding: 0px 7px;
  // border-right: 2px solid white;
}

.dealer-text {
  font-weight: 300;
  font-size: 14px;
  color: white;
  padding: 5px 7px;
}

.logo {
  background: url('../images/logos/logo.png') no-repeat center;
  background-size: contain;
  width: 104px;
  height: 54px;
  cursor: pointer;
  margin-right: 30px;
}

.address-worktime-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 40px;
  cursor: pointer;
}

.phone-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  color: white;
  font-size: 24px;
  margin-left: 40px;
}

.phone {
  color: white;
}

.office-address {
  font-weight: 300;
  font-size: 16px;
  color: white;
}

.office-worktime {
  font-weight: 300;
  font-size: 16px;
  color: white;
  text-transform: uppercase;
}

.buttons-container {
  margin-left: 50px;
  display: flex;
}

@media (max-width: 1615px) {
  .desktop {
    &.block-header-container {
      .content-container {
        height: 140px;
      }
    }
  }
}
</style>
