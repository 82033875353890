<template>
  <div class="bg-texture-offer-mobile h-[650px] bg-no-repeat bg-cover relative md:h-[513px] xl:h-[840px] md:bg-texture-offer-tablet 2xl:h-[848px] 2xl:bg-texture-offer 2xl:bg-top 2xl:after:w-full 2xl:after:h-full 2xl:after:absolute 2xl:after:bg-gradient-to-r 2xl:after:from-white/80 2xl:after:to-transparent" id="offer">
    <div class="absolute z-10 top-[28px] md:left-0 md:top-1/2 md:-translate-y-1/2 px-[5%] md:px-[9%] w-full">
      <div class="2xl:w-[44%] md:w-2/3 w-full">
        <p class="font-medium 2xl:text-[50px] md:text-[36px] uppercase text-[28px]">Есть другие предложения?</p>
        <p class=" md:mt-[30px] mt-[20px] text-[20px] md:text-[28px]">Сделаем более выгодное!</p>
        <div class="md:w-2/3 w-full">
          <callback-form-callback-input :data="{ type: 'sale', form: 'offer', placeholder: 'Контактный телефон' }" class="mt-[40px]">
            ОСТАВИТЬ
            ЗАЯВКУ
          </callback-form-callback-input>
        </div>
      </div>
    </div>
  </div>
</template>