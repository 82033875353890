<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4443 9.45039C19.1374 9.45039 18.8888 9.69889 18.8888 10.0056C18.8888 14.9041 14.9011 18.8895 9.9999 18.8895C5.09871 18.8895 1.11101 14.9041 1.11101 10.0056C1.11101 5.10721 5.09871 1.12177 9.9999 1.12177C12.0392 1.12177 13.9725 1.80511 15.5416 3.06063L13.8512 3.51331C13.5549 3.59263 13.379 3.89737 13.4582 4.19341C13.5249 4.44144 13.7493 4.6052 13.9944 4.6052C14.0422 4.6052 14.0907 4.599 14.1386 4.5863L16.8216 3.86777C17.0596 3.8041 17.2333 3.57791 17.2333 3.33145V0.555242C17.2333 0.248494 16.9847 0 16.6778 0C16.3709 0 16.1222 0.248494 16.1222 0.555242V2.10571C14.3777 0.748902 12.2462 0.0113031 10 0.0113031C4.48611 0.0113031 0 4.49488 0 10.0057C0 15.5164 4.48611 20 10 20C15.5139 20 20 15.5164 20 10.0057C20 9.6989 19.7514 9.45041 19.4444 9.45041L19.4443 9.45039Z"
      fill="currentColor"
    />
    <path
      d="M9.40893 7.21963L8.29782 4.25845C8.21644 4.0414 8.00919 3.89795 7.77762 3.89795H7.57021C6.60017 3.89795 5.68791 4.27549 5.002 4.9612C4.28398 5.67881 3.88843 6.63311 3.88843 7.64785C3.88843 8.31989 4.06746 8.98206 4.40648 9.56236C5.8614 12.0552 7.94879 14.1415 10.443 15.5955C11.0235 15.9341 11.6862 16.1132 12.3586 16.1132C13.3739 16.1132 14.3287 15.7182 15.0471 15.0003C15.733 14.3143 16.1108 13.4029 16.1108 12.4334V12.2264C16.1108 11.9949 15.9672 11.7878 15.7504 11.7065L12.7875 10.596C12.5835 10.5201 12.3539 10.5693 12.1996 10.7232L11.4163 11.506C10.2877 10.6957 9.30883 9.7174 8.49811 8.58959L9.28136 7.80678C9.43544 7.65294 9.4852 7.42334 9.40878 7.21945L9.40893 7.21963ZM11.1731 12.6884C11.3935 12.8355 11.6868 12.8061 11.874 12.6188L12.7323 11.7613L14.9936 12.6089C14.9521 13.2157 14.6958 13.7807 14.2613 14.2151C13.7531 14.7231 13.0775 15.0027 12.3588 15.0027C11.8826 15.0027 11.4138 14.8762 11.0031 14.6365C8.67389 13.2786 6.72448 11.3306 5.36615 9.00278C5.12635 8.5921 4.99971 8.12375 4.99971 7.64799C4.99971 6.92979 5.27965 6.2543 5.78776 5.74649C6.2224 5.31209 6.78756 5.056 7.39459 5.01448L8.24264 7.27479L7.38467 8.1326C7.19726 8.31959 7.16827 8.613 7.31507 8.83315C8.33923 10.3676 9.63713 11.665 11.1731 12.6884L11.1731 12.6884Z"
      fill="currentColor"
    />
  </svg>
</template>