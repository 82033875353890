import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import dealers from "../api/dealers";

export const useDealerStore = defineStore('dealers', () => {
  const all = ref(null)
  const getDealerById = computed((state) => (id) => all.value.find(dealer => dealer.id === id))
  function getAllDealers() {
    all.value = dealers.getDealers()
  }

  return {all, getDealerById, getAllDealers}
})