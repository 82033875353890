<template>
    <div
        @click="getCall({type: 'sale', form:'remote'})"
        class="block-remote">
        Работаем<br>
        дистанционно
    </div>
</template>

<script>
import emitter from 'tiny-emitter/instance'

export default {
    name: 'block-remote',
    methods: {
        getCall(data) {
          emitter.emit('getCall', data);
        }
    }
}
</script>

<style lang="scss" scoped>
.block-remote {
    position: fixed;
    top: 250px;
    right: 0;
    z-index: 1000;

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
    padding: 20px;

    font-weight: bold;
    text-align: center;
    color: #fff;

    background-color: #fe0000;
    cursor: pointer;
}
</style>