<template>
  <div class="best_offer" id="best-offer">
    <div class="container">
      <div class="content">
        <div class="title">
          ЕСТЬ ПРЕДЛОЖЕНИЕ<br />
          ОТ КОНКУРЕНТОВ?
        </div>
        <div class="desc">Сравните его с нашим</div>
        <callback-form-callback-input
          :data="{ form: 'best-offer', type: 'sale' }"
          input_class="flex lg:!flex-col gap-x-4"
          class="mt-[40px]"
        >
          Получить предложение
        </callback-form-callback-input>
      </div>
    </div>
  </div>
</template>

<script>
import CallbackInput from './callback-form/callback-input';

export default {
  name: 'best-offer',
  data() {
    return {};
  },
  props: {},

  components: {
    CallbackInput,
  },

  created() {},

  mounted() {},

  beforeDestroy() {},

  methods: {},

  computed: {},

  watch: {},

  directives: {},

  filters: {},
};
</script>

<style scoped lang="scss">
.best_offer {
  /* height: 600px; */
  padding-top: 120px;
  padding-bottom: 300px;
  background: url(../images/backgrounds/best_offer.jpg) 70% 0 no-repeat;
}
.best_offer .title {
  font-size: 42px;
  margin-bottom: 30px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: 'TacticSans', sans-serif;
}
.best_offer .desc {
  font-size: 24px;
  margin-bottom: 30px;
}
.best_offer form {
  width: 310px;
}
.best_offer form input[type='text'],
.best_offer form input[type='tel'] {
  color: #000;
  border-color: #000;
}
@media (max-width: 767px) {
  .best_offer {
    padding: 0;
    padding-top: 250px;
    background-size: 480px auto;
    background-color: #30363a;
    padding-bottom: 40px;
  }

  .best_offer .title {
    font-size: 28px;
    color: #fff;
  }
  .best_offer .desc {
    color: #fff;
  }
  .best_offer form {
    width: 100%;
  }
  .best_offer form .agree_fielda,
  .best_offer form .agree_field {
    color: #fff;
  }
}
</style>
