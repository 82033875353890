<template>
  <svg
    width="11"
    height="16"
    viewBox="0 0 11 16"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 6.07853C10.969 7.36947 10.6152 8.53094 9.95402 9.57784C8.72559 11.5226 7.48236 13.4565 6.24234 15.393C6.14724 15.5417 6.04243 15.6894 5.91933 15.81C5.66145 16.0626 5.33211 16.0626 5.07356 15.8141C4.95806 15.7034 4.85677 15.57 4.76799 15.432C3.51677 13.4886 2.25809 11.5502 1.02209 9.5952C-1.2934 5.93329 0.519292 1.01441 4.50779 0.109558C7.20933 -0.503181 10.0858 1.51986 10.7787 4.53918C10.8939 5.04144 10.9285 5.56519 11 6.07896L11 6.07853ZM5.49478 14.9107C6.73167 12.9737 7.93521 11.0893 9.13803 9.20428C9.21344 9.08661 9.28772 8.96818 9.3568 8.84576C10.0205 7.67278 10.2439 6.4076 10.0027 5.05389C9.57418 2.6492 7.40555 0.759377 5.27034 0.952601C3.45048 1.11675 2.10471 2.10087 1.32852 3.90268C0.566738 5.67256 0.719512 7.40612 1.72202 9.0294C2.39013 10.112 3.0872 11.1732 3.77346 12.2426C4.33547 13.1175 4.90047 13.9899 5.49471 14.9111L5.49478 14.9107Z"
      fill="currentColor"
    />
    <path
      d="M7.93146 6.08621C7.93497 7.58334 6.84975 8.77705 5.48133 8.78149C4.11389 8.78563 3.02361 7.59776 3.02051 6.10004C3.01742 4.60397 4.10207 3.40782 5.4684 3.40091C6.82699 3.39355 7.92796 4.59399 7.93149 6.08621H7.93146ZM7.06822 6.08345C7.06878 5.14903 6.35678 4.35759 5.5024 4.3433C4.61273 4.32842 3.88004 5.12464 3.88343 6.10309C3.88652 7.0619 4.61006 7.84689 5.48385 7.83908C6.36157 7.83187 7.06753 7.04949 7.06822 6.08345Z"
      fill="currentColor"
    />
  </svg>
</template>