<template>
  <div class="flex relative h-full">
    <div
      v-if="inscription"
      class="relative"
    >
      <div
        v-html="inscription"
        class="absolute -top-[17px] left-2 text-sm px-1 text-gray-600 whitespace-nowrap"
        :class="{
          'bg-white': theme === 'white' && !disabled,
          'bg-gray-100': theme === 'gray' || disabled,
        }"
      ></div>
    </div>
    <div
      v-if="prefix"
      class="absolute left-3 top-1/2 -translate-y-1/2"
    >
      {{ prefix }}
    </div>
    <input
      v-model="displayValue"
      ref="input"
      :type="type"
      :autocomplete="autocomplete"
      :autofocus="autofocus"
      :disabled="disabled"
      :inputmode="inputmode"
      :max="max"
      :min="min"
      :step="step"
      :maxlength="maxlength"
      :minlength="minlength"
      :name="name"
      :pattern="pattern"
      :placeholder="placeholder"
      :readonly="readonly"
      :required="required"
      class="leading-none w-full p-3 border border-solid rounded-brand text-gray-600"
      :class="[
        error ? '!border-red' : '',
        prefix ? 'pl-8' : '',
        theme === 'white' ? 'bg-white' : 'bg-transparent',
        classesInput,
      ]"
      @keyup="onKeyup($event)"
      @change="onKeyup"
      @focus="onFocus"
      @blur="onBlur"
    />
  </div>
</template>
<script>
import { isEqual } from 'lodash';
import Inputmask from 'inputmask';

import priceFormat from '../../common/priceFormat';

export default {
  name: 'KInput',
  props: {
    /**
     * Значение v-model родителя
     *
     * @type {String, Number}
     */
    modelValue: {
      type: [String, Number],
    },

    /**
     * Обновлять v-model только при blur
     */
    lazy: Boolean,

    /**
     * Атрибут type
     *
     * @type {Object}
     */
    type: {
      type: String,
      default: 'text',
    },

    /**
     * Атрибут autocomplete
     *
     * @type {Object}
     */
    autocomplete: {
      type: String,
      default: 'on',
    },

    /**
     * Атрибут autofocus
     *
     * @type {Object}
     */
    autofocus: {
      type: Boolean,
      default: false,
    },

    /**
     * Атрибут disabled
     *
     * @type {Object}
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Атрибут readonly
     *
     * @type {Object}
     */
    readonly: {
      type: Boolean,
      default: false,
    },

    /**
     * Атрибут required
     *
     * @type {Object}
     */
    required: {
      type: Boolean,
      default: false,
    },

    /**
     * Атрибут inputmode
     *
     * @type {String}
     */
    inputmode: String,

    /**
     * Атрибут max
     *
     * @type {Number}
     */
    max: Number,

    /**
     * Атрибут min
     *
     * @type {Number}
     */
    min: Number,

    /**
     * Атрибут step
     *
     * @type {Number}
     */
    step: Number,

    /**
     * Атрибут maxlength
     *
     * @type {Number}
     */
    maxlength: Number,

    /**
     * Атрибут minlength
     *
     * @type {Number}
     */
    minlength: Number,

    /**
     * Атрибут name
     *
     * @type {String}
     */
    name: String,

    /**
     * Атрибут pattern
     *
     * @type {String}
     */
    pattern: String,

    /**
     * Атрибут placeholder
     *
     * @type {String}
     */
    placeholder: String,

    label: String,

    /**
     * Визуально форматирование значения input
     * На фактическое значение, передаваемое в v-model,
     * не влияет
     *   - price
     *   - volume
     *   - phone
     *   - email
     */
    format: String,

    /**
     * Тема оформления
     *   - white
     *   - gray
     */
    theme: {
      type: String,
      default: 'vikup',
    },

    sufix: String,

    prefix: {
      type: String,
      default: null,
    },
    classesInput: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: null,
    },
    inscription: {
      type: String,
    },
    followInput: {
      type: Boolean,
      default: true,
    }

  },
  data() {
    return {
      displayValue: null,
      inputValue: null,
    };
  },
  mounted() {
    Inputmask.extendDefinitions({
      g: {
        validator: '[авекмнорстухАВЕКМНОРСТУХabekmhopctyxABEKMHOPCTYX]',
      },
    });
    if (this.format === 'gosNumber') {
      Inputmask('g 999 gg 999').mask(this.$refs.input);
    }
    if (this.format === 'phone') {
      Inputmask('+7 (999) 999-99-99').mask(this.$refs.input);
    }
    if (this.modelValue || this.modelValue === 0) {
      this.displayValue = this.modelValue;
      this.onKeyup();
      this.checkSufix();
    }
  },
  watch: {
    /**
     * При изменении значения на стороне родителя,
     * обновляем занчение в компоненте
     */
    modelValue(newValue) {
      if (!isEqual(newValue, this.inputValue)) {
        this.displayValue = newValue;
        this.onKeyup();
        this.checkSufix();
        if (!this.lazy) {
          this.emit();
        }
      }
    },
  },
  methods: {
    /**
     * При вводе данных в input,
     * проверяем на необходимость в форматировании значения
     * и эмитим актуальное значение в родительский v-model
     */
    onKeyup(event) {
      if (this.format === 'price') {
        let newValue = (String(this.displayValue).match(/\d/g) || []).join('');
        if (newValue === '') {
          this.inputValue = null;
        } else {
          this.inputValue = parseInt(newValue);
        }
        this.displayValue = String(this.displayValue).length > 0 ? priceFormat(this.inputValue) : null;
      } else if (this.format === 'number') {
        let newValue = (String(this.displayValue).match(/\d/g) || []).join('');
        if (newValue === '') {
          this.inputValue = null;
        } else {
          this.inputValue = parseInt(newValue);
        }
        this.displayValue = this.displayValue >= 0 ? this.inputValue : null;
      } else if (this.format === 'phone') {
        let rule = /^\+7\s\([1-9].*/;
        this.check(rule);
      } else {
        this.inputValue = this.displayValue === '' ? null : this.displayValue;
      }
      if (this.followInput) {
        if (!this.lazy) {
          this.emit();
        }
      } else {
        if (event) {
          if (event.code === 'Enter') {
            if (this.inputValue < this.min) {
              this.inputValue = this.min;
            } else if (this.inputValue > this.max) {
              this.inputValue = this.max;
            }

            this.emit();
          }
        } else {
          this.emit();
        }
      }
    },

    emit() {
      this.$emit('update:modelValue', this.inputValue);
    },

    check(rule) {
      let newValue = ((this.displayValue || '').toString().match(rule) || []).join('');
      if (newValue == '') {
        this.inputValue = null;
      } else {
        this.inputValue = newValue;
      }
      this.displayValue = this.inputValue || null;
    },

    checkSufix() {
      if ((this.displayValue || this.displayValue === 0) && this.sufix && !String(this.displayValue).endsWith(this.sufix)) {
        this.displayValue += this.sufix;
      }
    },

    onFocus() {
      if ((this.displayValue || this.displayValue === 0) && this.sufix && String(this.displayValue).endsWith(this.sufix)) {
        this.displayValue = this.displayValue.substr(0, this.displayValue.length - this.sufix.length);
      }
    },

    onBlur() {
      if (this.inputValue < this.min) {
        this.displayValue = Number(this.min);
        this.onKeyup();
      }
      if (this.inputValue > this.max) {
        this.displayValue = Number(this.max);
        this.onKeyup();
      }

      this.checkSufix();

      if (this.lazy) {
        this.emit();
      }
    },
  },
};
</script>