<template>
  <div class="block-legal-container" :class="[device_platform]">

    <div class="socials-container">
      <a class="socials" v-for="social in socials" :class="'socials__' + social.name" :key="social.name"
        :href="social.link" target="_blank"></a>
    </div>
    <div class="expander">
      <div class="expander-button" @click="expanded = !expanded">
        Подробные условия акции
      </div>
    </div>
    <transition name="slide">
      <div class="text-container" v-show="expanded">
        <div class="legal-text-block">
          <p>1) Выгода, применяемая к цене автомобиля, предоставляется Дилером покупателю от розничной цены автомобиля. Программа действует с 01.05.2023 г. Условия настоящей программы могут сочетаться с условиями «Программы Trade-in».  Программа действует с 01.05.2023 г. по 31.05.2023 г. ООО «ДЖИЛИ-МОТОРС» вправе изменить сроки и условия Программы. Подробные условия уточняйте у менеджеров в дилерских центрах «Geely» (список Дилеров: www.geely-motors.com) либо по тел.: 8 800 200 02 89</p>
          <p>2) Выгода по программе "Трейд-ин" это единовременная и разовая скидка по программе "Трейд-ин", предоставляется дилером покупателю от розничной цены автомобиля, приобретаемого по Программе, при сдаче в трейд-ин автомобиля с пробегом. Информацию о брендах и моделях, участвующих в программе, уточняйте у официальных дилеров. Условия настоящей программы не могут сочетаться с условиями «Программы на покупку автомобиля с использованием кредитных средств банка», «Программы поддержки корпоративных продаж», «Geely Лизинг». Программа действует с 01.05.2023 г. по 31.05.2023 г. ООО «ДЖИЛИ-МОТОРС» вправе изменить сроки и условия Программы. Подробные условия уточняйте у менеджеров в дилерских центрах «Geely» (список Дилеров: www.geely-motors.com) либо по тел.: 8 800 200 02 89 (бесплатно по РФ). Реклама. ООО «ДЖИЛИ-МОТОРС». Не оферта.</p>
          <p>3) Выгода по программе «Geely Finance» предоставляется при покупке нового автомобиля «Geely» в кредит в любом банке: для «Geely Coolray» в размере 185 000 рублей,  для «Geely Atlas Pro 1,5 AT 2WD», «Geely Tugella» и «Geely Monjaro» в размере 200 000 рублей, для «Geely Atlas Pro 1,5 DCT 4WD» в размере 220 000 рублей. Условия настоящей программы не могут сочетаться с условиями «Программы Trade-in», «Программы поддержки корпоративных продаж», «Geely Лизинг». Программа действует с 01.05.2023 г. по 31.05.2023 г. ООО «ДЖИЛИ-МОТОРС» вправе изменить сроки и условия Программы. Подробные условия уточняйте у менеджеров в дилерских центрах «Geely» (список Дилеров: www.geely-motors.com) либо по тел.: 8 800 200 02 89 (бесплатно по РФ). Реклама. ООО «ДЖИЛИ-МОТОРС». Не оферта.</p>
          <p>4) Выгода по программе «Лояльный Трейд-ин» - это единовременная и разовая скидка, предоставляется дилером покупателю от розничной цены автомобиля, приобретаемого по Программе, при сдаче в трейд-ин автомобиля марки «Geely». Условия настоящей программы не могут сочетаться с условиями «Программы на покупку автомобиля с использованием кредитных средств банка». Программа действует с 01.05.2023 г. по 31.05.2023 г. Реклама. ООО «ДЖИЛИ-МОТОРС». Не оферта. ООО «ДЖИЛИ-МОТОРС» вправе изменить сроки и условия Программы. Подробные условия уточняйте у менеджеров в официальных дилерских центрах «Geely» (список Дилеров: www.geely-motors.com) либо по тел.: 8 800 200 02 89 (бесплатно по РФ).</p>
          <p>5) Выгода по программе «Специальный Трейд-ин» - это единовременная и разовая скидка, предоставляется дилером покупателю от розничной цены автомобиля, приобретаемого по Программе, при сдаче в трейд-ин автомобиля популярной марки (список марок уточняйте в дилерских центрах «Geely»). Условия настоящей программы не могут сочетаться с условиями «Программы на покупку автомобиля с использованием кредитных средств банка». Программа действует с 01.05.2023 г. по 31.05.2023 г. Реклама. ООО «ДЖИЛИ-МОТОРС». Не оферта. ООО «ДЖИЛИ-МОТОРС» вправе изменить сроки и условия Программы. Подробные условия уточняйте у менеджеров в официальных дилерских центрах «Geely» (список Дилеров: www.geely-motors.com) либо по тел.: 8 800 200 02 89 (бесплатно по РФ).</p>
          <p>*Выгода в размере 570 000 рублей указана для автомобилей «Geely Coolray» (все комплектации, кроме Comfort и Luxury), счет по которым выставлен ООО «ДЖИЛИ-МОТОРС» с 01.07.2022г., и состоит из выгоды в размере 335 000 рублей, применяемой к цене автомобиля, и выгоды по программе «Трейд-ин» в размере 235 000 рублей. Выгода 235 000 рублей на указанные автомобили предоставляется при сдаче в Трейд-ин автомобиля «Geely» или другой популярной марки (список марок уточняйте в дилерских центрах «Geely»). Программа действует с 15.04.2023 г. по 31.05.2023 г.</p>
          <p>**Выгода в размере 640 000 рублей указана для автомобилей «Geely Atlas Pro» (все комплектации, кроме 1.5T AT 2WD Comfort и 1.5T AT 2WD Luxury), счет по которым выставлен ООО «ДЖИЛИ-МОТОРС» с 01.07.2022г., и состоит из выгоды в размере 370 000 рублей, применяемой к цене автомобиля, и выгоды по программе «Трейд-ин» в размере 270 000 рублей. Выгода 270 000 рублей на указанные автомобили предоставляется при сдаче автомобиля «Geely» или другой популярной марки (список марок уточняйте в дилерских центрах «Geely») в Трейд-ин (в зачет стоимости нового автомобиля). Программа действует с 15.04.2023 г. по 31.05.2023 г.</p>
          <p>*** Выгода в размере 590 000 рублей указана для автомобилей «New Geely Tugella» 2022 модельный год (все комплектации), счет по которым выставлен ООО «ДЖИЛИ-МОТОРС» с 01.07.2022г., и состоит из выгоды в размере 400 000 рублей, применяемой к цене автомобиля, и выгоды по программе «Трейд-ин» в размере 250 000 рублей. Выгода 250 000 рублей на указанные автомобили предоставляется при сдаче в Трейд-ин автомобиля «Geely» или другой популярной марки (список марок уточняйте в дилерских центрах «Geely»). Программа действует с 15.04.2023 г. по 31.05.2023 г.</p>
          <p>**** Выгода в размере 250 000 рублей указана для автомобилей «Geely Monjaro» (все комплектации), и состоит из выгоды по программе «Трейд-ин» в размере 250 000 рублей. Выгода 250 000 рублей на указанные автомобили предоставляется при сдаче в Трейд-ин автомобиля «Geely» или другой популярной марки (список марок уточняйте в дилерских центрах «Geely»). Программа действует с 01.05.2023 г. по 31.05.2023 г.</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Mixin from "../common/mixin";
import CF from "../common/common-functions";

export default {
  name: "block-legal",
  mixins: [Mixin],
  data() {
    let today = new Date();
    return {
      expanded: false,
      end_date:
        this.getLastDayOfMonth(today.getFullYear(), today.getMonth()) +
        " " +
        CF.matchMonth(today.getMonth()) +
        " " +
        today.getFullYear() +
        " г.",
      start_date:
        this.getFirstDayOfMonth(today.getFullYear(), today.getMonth()) +
        " " +
        CF.matchMonth(today.getMonth()) +
        " " +
        today.getFullYear() +
        " г.",

      socials: [
        { name: "vk", link: "https://vk.com/geely_kuntsevo" },
        {
          name: "youtube",
          link:
            "https://www.youtube.com/channel/UCzaqpxhGDGkTmjKc6FmDRcg?view_as=subscriber",
        },
        { name: "telegram", link: "https://t.me/tckuntsevo" },
      ],
    };
  },
  computed: {
    lastDayOfMonth() {
      const today = new Date(Date.now() + 10800000);
      const lastDayCurrentMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0
      );
      return new Date(lastDayCurrentMonth).toLocaleDateString("ru", {
        year: "numeric",
        month: "long",
        day: "2-digit",
      });
    },
    firstDayOfMonth() {
      const today = new Date(Date.now() + 10800000);
      const firstDayCurrentMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        1
      );
      return new Date(firstDayCurrentMonth).toLocaleDateString("ru", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
  },
  methods: {
    getLastDayOfMonth(year, month) {
      let date = new Date(year, month + 1, 0);
      return date.getDate();
    },
    getFirstDayOfMonth(year, month) {
      let date = new Date(year, month, 1);
      return date.getDate();
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/constants.scss";

.mobile {
  &.block-legal-container {
    padding-top: 40px;
  }

  .legal-text-block {
    padding: 30px 10px 40px;
  }

  .socials-container {
    padding-top: 0;
  }
}

.tablet {}

.block-legal-container {
  width: 100%;
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #efefef;
  position: relative;
}

.expander-button {
  margin-top: 20px;
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
}

.socials-container {
  width: 220px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
}

.socials {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin: 0 6px;
  opacity: 1;
  transition: 0.1s;

  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.9);
  }

  &__facebook {
    background-image: url("../images/social/facebook.png");
  }

  &__youtube {
    background-image: url("../images/social/youtube.png");
  }

  &__vk {
    background-image: url("../images/social/vk.png");
  }

  &__telegram {
    background-image: url("../images/social/telegram.png");
  }

  &__instagram {
    background-image: url("../images/social/instagram.png");
  }
}

.text-container {
  width: 100%;
}

.legal-text-block {
  width: 100%;
  padding: 30px 50px 40px;
  margin: 0 auto;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  color: black;
}

.slide-enter-active {
  transition: all 0.3s ease;
}

.slide-leave-active {
  transition: all 0.3s;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

p {
  margin-block-start: 1em;
  margin-block-end: 1em;
}

ul {
  padding-left: 28px;
  padding-top: 8px;
}
</style>
