$(function () {
  // const { host, hostname, href, origin, pathname, port, protocol, search } =
  //   window.location;
  // const searchParams = new URLSearchParams(search);
  // if (searchParams.has('block')) {
  //   scrollTo($(`#${searchParams.get('block')}`));
  // }
  $('[data-href]').on('click', function (e) {
    e.preventDefault();
    if ($(e.target).attr('href')) return;

    let target = $('#' + $(this).attr('data-href'));
    if ($('.mobile-menu').hasClass('open')) {
      $('.mobile-menu').removeClass('open');
      document.body.style.overflow = 'auto';
    }
    scrollTo(target);
  });
  function scrollTo(target) {
    $('html, body').animate(
      {
        scrollTop:
          target.offset().top -
          $('#app > header').height() +
          ($(target).innerHeight() - $(target).height()) / 2,
      },
      550,
    );
  }
});
