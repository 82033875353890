const _dealers = [
  {
    id: 1,
    name: "АТЦ Кунцево",
    city: "Москва",
    address: "улица Горбунова, 14",
    phone: "+7 (495) 933-40-33",
    opening_hours: "08:00",
    closing_hours: "21:00",
    coords: ["55.725929", "37.374102"],
    reviews: [
      {
        name: 'Сергей Янушкевич',
        date: '18 июля 2022',
        text: 'Не плохой салон ! Но можно быть лучше!!!',
        expanded: false
      },
      {
        name: '189532 Бурмистрова',
        date: '24 июня 2022',
        text: 'Время ожидания перечеркиваеи все достоинства',
        expanded: false
      },
      {
        name: 'Мимопроходил',
        date: '14 июня 2022',
        text: 'Персонал, в целом, квалифицированный, свободно разбирается в своих авто, могут все рассказать-показать сами, не приглашая других менеджеров. Без всяких скрытых комиссий, честные договора, предоплаты, в случае отказа со стороны клиента, возвращают без вопросов и проволочек. Единственное, в выходные большой поток людей, поэтому долго приходится ждать пока освободится менеджер',
        expanded: false
      },
      {
        name: 'Юлия К.',
        date: '3 июня 2022',
        text: 'Впервые обратилась в этот автосервис, после неудачного опыта с мейджором. Отличный автосервис, я очень довольна. Работа была сделана на высшем уровне и быстро ! Большое спасибо Дмитрию Карабасову.',
        expanded: false
      },
      {
        name: 'Andrey Prohorov',
        date: '17 мая 2022',
        text: 'Нашёл нужный мне авто, согласовали хорошую скидку, забрал на следующий день. В целом все понравилось.',
        expanded: false
      },
      {
        name: 'Анна',
        date: '27 февраля 2022',
        text: 'Отличный салон с самыми адекватными предложениями по Москве. Достоверная информация, не навязывают лишнее. Отдельная благодарность нашему менеджеру Кириллу Загайнову за профессиональную работу, деликатный подход, отзывчивость в решение всех трудностей и доброжелательность',
        expanded: false
      },
      {
        name: 'Александр',
        date: '21 февраля 2022',
        text: 'Покупал новую машину, условия супер, менеджеру Борису, оценщику Ивану отдельный респект. Рекомендую',
        expanded: false
      },

      {
        name: 'Елена бродецкая',
        date: '19 февраля 2022',
        text: 'Отличный менеджер Дроздов Святослав!',
        expanded: false

      },
      {
        name: 'Николай',
        text: 'Все́ понравилось. Быстро, честно и по делу',
        date: '18 янв 2022',
        expanded: false,
      },
      {
        name: 'Петр Смирнов',
        text: 'Приятный, приветливый, отзывчивый персонал, специалисты своего дела.иногда бывают выгодные спецпедложения',
        date: '12 янв 2022',
        expanded: false,
      },
      {
        name: 'Борис Коновалов',
        text: 'Сервис на высшем уровне!Спасибо руководителям за качественно налаженную работу персонала!',
        date: '11 янв 2022',
        expanded: false,
      },
      {
        name: 'Иван Рысухин',
        text: 'Отличный персонал, не стали втюхивать допов на миллионы денег!',
        date: '4 янв 2022',
        expanded: false,
      },
      {
        name: 'Роман Потапов',
        text: 'Хороший дилерский автоцентр, машину купил за пару дней в конце декабря 2020г. Сравнивал несколько официалов, цена примерно была везде одинаковая, но разные допы установленные на машине (на это обращайте внимание), торгуйтесь конечно, отстаивайте свои интересы, если хотите))!',
        date: '28 дек 2021',
        expanded: false,
      },
      {
        name: 'Мирослав Ц.',
        text: 'Приобретал здесь автомобиль, прохожу теперь здесь то, грамотные специалисты',
        date: '9 дек 2021',
        expanded: false,
      },
      {
        name: 'Дмитрий Г.',
        text: 'Хотел сказать спасибо менеджеру Николаю!Предисловие. Сам собственник бизнеса, отношусь к процессу покупки нового авто очень скрупулезно и поэтому взял с собой команду. Юрист + технарь.Вне зависимости от этих факторов, Николай отнёсся с понимаем. Все показал и рассказал. Конечно соблюдая рамки внутреннего регламента. Было пару небольших заминок, но понимаю процессы и отношусь к этому адекватно. Спасибо салону за более чем конкурентные условия и предложение.',
        date: '8 дек 2021',
        expanded: false,
      },
      {
        name: 'Камилла Зуева',
        text: 'Очень приветливый, дружный, постоянный состав сотрудников!',
        date: '7 дек 2021',
        expanded: false,
      },
      {
        name: 'Владимир Шуляпов',
        text: 'Менеджер все рассказал, показал. Но к сажелению пока не выбрали автомобиль',
        date: '5 дек 2021',
        expanded: false,
      },
      {
        name: 'Глеб Владимиров',
        text: 'Хороший салон, сотрудники к клиенту со всей душой, все на приличном уровне',
        date: '2 дек 2021',
        expanded: false,
      },
      {
        name: 'Iviv Ad',
        text: 'Хороший выбор автомобилей',
        date: '26 ноя 2021',
        expanded: false,
      },
      {
        name: 'Леся',
        text: 'Была на тест драйве, ребята большие молодцы. Теперь серьезно думаю о покупке',
        date: '18 ноя 2021',
        expanded: false,
      },
      {
        name: 'Лара Гущина',
        text: 'Огромное спасибо, что привезли наш автомобиль быстрее, чем мы ожидали. Вы лучшие!',
        date: '26 окт 2021',
        expanded: false,
      },
      {
        name: 'Eliana Likhacheva',
        text: 'Хорошая цена, искали долго в полной комплектации Джили Атлас. Купили у вас и не пожалели.',
        date: '26 окт 2021',
        expanded: false,
      },
      {
        name: 'Татьяна Мазепина',
        text: 'Хороший центр. Помогли выбрать автомобиль. Тест драйв на разных моделях не проблема. В отличии от других дилеров можно и в кредит и за наличный расчёт. За обслуживание пятёрка. Не хватает зоны ожидания и кофейного аппарата, ну по крайней мере для меня. Немного комфорта нужно добавить. Как правило в салоне проводишь не один час. А так все отлично',
        date: '22 окт 2021',
        expanded: false,
      },
      {
        name: 'Андрей Шаронов',
        text: 'Отличный сервис с отзывчивыми профессиональными сотрудниками, динамично развивающийся молодой бренд.',
        date: '15 окт 2021',
        expanded: false,
      },
      {
        name: 'Zaitsev',
        text: 'Приняли по гарантии, менеджеры компетентны и вежливы.',
        date: '16 сен 2021',
        expanded: false,
      },
      {
        name: 'Gleb Moiseev',
        text: 'По всем параметрам отличный салон!! Если покупать, то только здесь',
        date: '16 сен 2021',
        expanded: false,
      },
      {
        name: 'В Л',
        text: 'Ремонтировал свой автомобиль Джили Кулрей по Каско в техцентре (кузовной цех). Мастер приемщик Дмитрий Карабасов отличный специалист, спокойный, вежливый. Немного затянули со сроками, по вине поставщика запчастей. Но в целом все хорошо, в цвет попали идеально, были вопросы к зазорам, но были на месте исправлены).Попутно пообщался с ребятами из слесарного цеха, остался доволен по тем же причинам. моя оценка 5',
        date: '15 сен 2021',
        expanded: false,
      },
      {
        name: 'Open Spase',
        text: 'Очень удобно в одном месте столько брендов. Сразу все сравнил и пощупал.',
        date: '28 авг 2021',
        expanded: false,
      },
      {
        name: 'Артур В.',
        text: 'Сервис с нормальными ценами, никто не навязывает доп работы',
        date: '11 авг 2021',
        expanded: false,
      },
      {
        name: 'Lapin Arslan',
        text: 'Мы были очень придирчивы в процессе покупки, но менеджеры отработали наши запросы на 100 баллов, молодцы!',
        date: '9 авг 2021',
        expanded: false,
      },
      {
        name: 'Яков',
        text: 'Менеджер доброжелательный и тактичный, владеет информацией.',
        date: '8 мая 2021',
        expanded: false,
      },
      {
        name: 'Александр Б.',
        text: 'Отличный и отзывчивый персонал, все ясно и доступно объясняет.',
        date: '16 апр 2021',
        expanded: false,
      },
      {
        name: 'Дмитрий Обухов',
        text: 'Надёжный дилер, квалифицированный персонал, предоставляют выгодные спецпредложения, в том числе выгодный трейд-ин для медицинских работников, корпоративный отдел успешно сотрудничает с юрлицами. Автомобили надежные и комфортные, с конкурентной ценой.',
        date: '24 янв 2021',
        expanded: false,
      },
      {
        name: 'Colombina Colombina',
        text: 'Да, в Москве в АТЦ Кунцево реально купить авто без навязанных доп. опций, по нынешним временам это - роскошь невиданная!))',
        date: '17 янв 2021',
        expanded: false,
      },
      {
        name: 'Mariya Buslova',
        text: 'Отличный менеджер отдела продаж Борис. Всегда на связи, ответит на любой вопрос. Покупали в этом салоне автомобиль, остались очень довольны',
        date: '18 ноя 2020',
        expanded: false,
      },
      {
        name: 'Евгений',
        text: 'Автосалон очень понравился, хорошая выставка машин, есть в наличии. Рекомендую обращаться к менеджеру Владимиру Галябовичу. Грамотный профессионал своего дела. Про продаваемые модели машин может рассказать, как плюсы, так и реальные минусы, которые могут быть.',
        date: '24 сен 2020',
        expanded: false,
      },
      {
        name: 'svetlana g.',
        text: 'Отличный центр! Грамотный персонал. Работают быстро, оперативно!',
        date: '27 авг 2020',
        expanded: false,
      },
      {
        name: 'Елена Кулева',
        text: 'Профессиональный и приветливый персонал. Подьезд удобный.',
        date: '19 авг 2020',
        expanded: false,
      },
      {
        name: 'Алена Ш.',
        text: 'Приобрели машинку у этого диллера... что я могу сказать?!... да огонь! Диллер бомба. Персонал абсолютно спокойный, приветливый, без лишних слов и навязок всякой фигни не нужной, все по делу! Пользуясь случаем выражаю ОГРОМНУЮ благодарность менеджеру Кириллу! Вы лучший менеджер и профессионал своего дела! Кирилл, спасибо вам за все! Спасибо за работу, за понимание и за такую крутую тачку что мы взяли!) Спасибо что вы нас поддерживали и не давали ни на секунду сомневаться в нашем выборе! Обожаю свою ласточку Атлас!!! Безумно благодарна вам за вашу работу и за такой чудесный день! Желаю всем побольше таких людей и менеджеров как вы! Душевный человек без всяких лишних навязок, все как надо! СПАСИБО Кирилл!!!! Взяли мы Джили Атлас 2,4 автомат, полный привод, беленькую ласточку нашу! Огромная благодарность Джили Кунцево! все грамотно, все четко, все быстро и по делу !',
        date: '18 авг 2020',
        expanded: false,
      },
      {
        name: 'Ирина С..',
        text: 'Здравствуйте, вчера купили машину GEELY COOLRAY 2020.Обменяли свои автомобиль по программе tr-in Ниссан Альмера. Выражаем свою благодарность менеджеру Галябович Владимир . Много допов получили. Все быстро и четко .Остались довольны.Рекомендуем',
        date: '20 июн 2020',
        expanded: false,
      },
      {
        name: 'Елена Теплякова',
        text: 'Супер. Менеджер Владимир, очень подробно все рассказал, посоветовал автомобиль. Остались довольны с мужем. Сервис на высоте! Рекомендую.',
        date: '15 июн 2020',
        expanded: false,
      },
      {
        name: 'Александр Ф.',
        text: 'Отличное место для покупки автомобиля',
        date: '8 апр 2020',
        expanded: false,
      },
      {
        name: 'Boris',
        text: 'Грамотные менеджеры. Отличное обслуживание . Профессиональная работа смежных отделов.',
        date: '2 апр 2020',
        expanded: false,
      },
      {
        name: 'Ян',
        text: 'Приятные, доброжелательные ребята менеджеры, здоровый, дружный коллектив.',
        date: '2 мар 2020',
        expanded: false,
      },
      {
        name: 'Gres',
        text: 'Замечательный салон приветливые менеджеры, оформились быстро вовремя без разводов',
        date: '2 мар 2020',
        expanded: false,
      },
      {
        name: 'Сергей Р.',
        text: 'Стали счастливыми обладателями Geely Atlas. Вечером приехали посмотрели, с нами очень хорошо поработал Николай Дзюба, все рассказал, показал, обьяснил. Мы внесли предоплату, обо всем договорились и уже на след день нам позвонил Николай и сказал, что машина готова, можно забирать. Никаких долгих ожиданий, все быстро, качественно и честно, за что большое спасибо Николаю и салону!',
        date: '6 янв 2020',
        expanded: false,
      }
    ],
    socials: [
        { name: "vk", link: "https://vk.com/geely_kuntsevo" },
        {
          name: "youtube",
          link:
            "https://www.youtube.com/channel/UCzaqpxhGDGkTmjKc6FmDRcg?view_as=subscriber",
        },
        { name: "telegram", link: "https://t.me/tckuntsevo" },
      ],
  },
  {
    id: 2,
    name: "",
    city: "Санкт-Петербург",
    address: "",
    phone: "",
    opening_hours: "",
    closing_hours: "",
    coords: [""],
    reviews: null
  }
];

export default {
  getDealers() {
    return _dealers;
  }
};