import { ref } from 'vue';
import { defineStore } from 'pinia';
import legal_info from "../api/legal_info";

export const useMessageStore = defineStore('messages', () => {
  const all = ref(null);
  const messageType = ref(null)

  function getMessageType(payload) {
    messageType.value = payload.type
  }
  const getAllAgreement = () => {
    all.value = legal_info.getAgreement()
  }
  const getAllConditions = () => {
    all.value = legal_info.getConditions()
  }

  function $reset() {
    all.value = null;
    messageType.value = null;
  }

  return { all, messageType, getMessageType, getAllAgreement, getAllConditions, $reset };
})