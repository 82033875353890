<template>
  <div class="relative ">
    <div class="cursor-pointer absolute top-4 right-4 w-8 h-8 before:content-[''] before:absolute before:w-8 before:h-px before:bg-black before:rotate-45 after:content-[''] after:absolute after:w-8 after:h-px after:bg-black after:-rotate-45 z-30" @click="modalStore.hideModal" ></div>
    <div class="bg-white rounded-md z-10 relative overflow-y-auto max-h-[350px] md:max-h-full md:h-auto xl:max-h-[500px] 2xl:overflow-auto">
      <div class="container py-4 relative">
        <div class="p-[9%] md:p-[5%]">
          <div v-show="messageStore.messageType === 'agreement'"><h3 class="text-center uppercase text-black font-semibold text-3xl mb-4" >{{ modalMessage?.title }}</h3></div>
          <div v-show="messageStore.messageType === 'agreement'" class="text-[14px]"> {{ modalMessage?.text }}</div>
          <div v-show="messageStore.messageType=='conditions'">
            <h3 class="text-center uppercase text-black font-semibold text-3xl mb-4">Подробные условия акции</h3>
          </div>
          <div v-show="messageStore.messageType === 'conditions'" class="text-[14px]">
            <div v-for="item in modalMessage">
              {{ item.prefix }} {{ item.text }}
            </div>
          </div>
          <div v-show="messageStore.messageType === 'success'">
            <h3 class="text-center uppercase text-black font-semibold text-3xl mb-4">Спасибо за оставленную заявку!</h3>
            <p class="text-2xl text-center"> Наши менеджеры свяжутся с Вами в ближайшее время.</p>
          </div>
          <div v-show="messageStore.messageType === 'error'">
            <h3 class="text-center uppercase text-black font-semibold text-3xl mb-4">Произошла ошибка при отправке!</h3>
            <p class="text-2xl text-center">Попробуйте оставить заявку позднее.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useModalStore } from "@/stores/modalStore";
import { useMessageStore } from "@/stores/messageStore";
import { storeToRefs } from 'pinia'

const modalStore = useModalStore()
const messageStore = useMessageStore()
const { modalMessage } = storeToRefs(modalStore)
</script>