(function () {
  let opened = false;
  document.querySelectorAll(".menu-trigger").forEach(function (el) {
    el.addEventListener("click", function () {
      document.querySelectorAll(".mobile-menu").forEach(function (item) {
        item.classList.toggle("open");

        if (opened) {
          document.body.style.overflow = "auto";
          opened = false;
        } else {
          document.body.style.overflow = "hidden";
          opened = true;
        }
      });
    });
  });
})();
