<template>
  <div class="pl-4">
    <checkbox-item
      v-model="checkbox"
      :checked="checked"
      @click="onOptionClick"
    >
      <span
        class="w-full py-4 pl-1 pr-4 text-gray-600"
        v-html="option"
      ></span>
    </checkbox-item>
  </div>
</template>

<script>
import CheckboxItem from './CheckboxItem';

export default {
  name: 'SelectOption',
  components: {
    CheckboxItem,
  },
  props: {
    option: {
      type: [String, Number],
      required: true,
    },
    isOptionSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: !this.isOptionSelected,
      checkbox: false,
    };
  },
  watch: {
    isOptionSelected() {
      this.checked = !this.isOptionSelected;
    },
  },
  methods: {
    onOptionClick() {
      this.$emit('on-option-click', this.option);
    },
  },
};
</script>