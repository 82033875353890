<template>
  <svg
    id="check"
    width="20"
    height="16"
    viewBox="0 0 20 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      stroke="none"
      clip-rule="evenodd"
      d="M19.4831 0.540726C20.1723 1.26169 20.1723 2.43061 19.4831 3.15158L7.71842 15.4593C7.02926 16.1802 5.91191 16.1802 5.22275 15.4593L0.51687 10.5362C-0.17229 9.81523 -0.17229 8.64631 0.51687 7.92534C1.20603 7.20437 2.32338 7.20437 3.01254 7.92534L6.47059 11.543L16.9875 0.540726C17.6766 -0.180242 18.794 -0.180242 19.4831 0.540726Z"
    />
  </svg>
</template>