<template>
  <div class="block-main-container" :class="[device_platform]">
    <div ref="prev"
      class="arrow arrow-left"
      v-if="device_platform !== 'mobile' && array_of_slides.length > 1"
    ></div>
    <div ref="next"
      class="arrow arrow-right"
      v-if="device_platform !== 'mobile' && array_of_slides.length > 1"
    ></div>
    <swiper
        :loop="this.array_of_slides.length > 1"
        :simulate-touch="false"
        :autoplay="this.array_of_slides.length > 1"
        :delay="15000"
        :navigation="{
          prevEl: prev,
          nextEl: next,
        }"
        :modules="modules"
        class="swiper-container-main">
      <swiper-slide class="swiper-slide" v-for="i in array_of_slides" :key="i">

          <div class="content-container" :class="'_' + i" v-if="i === 1">
            <div class="header-container">
              <div class="header">
                <span class="bunner-title">GEELY НА ОСОБЫХ УСЛОВИЯХ!</span>
                <div class="banner-subtitle">
                  <span style="white-space: nowrap">ВЫГОДА до <b>640 000 ₽</b></span><br/>
                  В КРЕДИТ от <b>0,01%</b><br/>
                  <b>СПЕЦПРЕДЛОЖЕНИЕ для ИП и юр.лиц!</b>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container" :class="'_' + i" v-if="i === 3">
          </div>
          <div class="content-container" :class="'_' + i" v-if="i === 8">
            <div class="header-container">
              <div class="header">
                <span class="bunner-title">Пройдите<br>тест-драйв</span>
              </div>
            </div>
          </div>

          <div class="content-container" :class="'_' + i" v-if="i === 6">
            <div class="header-container">
              <div class="header">
              <span class="bunner-title">GEELY ATLAS PRO</span><br />
                <span style="white-space: nowrap">без дилерской наценки!</span><br />
                <br />
                <span style="white-space: nowrap">ВЫГОДА до <b>{{is_lb ? '590 000': '590 000'}} ₽</b></span><br />
                В КРЕДИТ от <b>0,01%</b><br />
                <b>СКИДКА для ИП и юр.лиц до 18%</b>
              </div>
            </div>
          </div>
          <div class="content-container" :class="'_' + i" v-if="i === 7">
            <div class="header-container">
              <div class="header">
              <span class="bunner-title">GEELY TUGELLA</span><br />
                <span style="white-space: nowrap">без дилерской наценки!</span><br />
                <br />
                <span style="white-space: nowrap">ВЫГОДА до <b>{{is_lb ? '650 000': '650 000'}} ₽</b></span><br />
                В КРЕДИТ от <b>0,01%</b><br />
                <b>СКИДКА для ИП и юр.лиц до 18%</b>
              </div>
            </div>
          </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { ref } from 'vue';
import Mixin from "../common/mixin";
import finance from "../common/finance";
import CallbackInput from "./callback-form/callback-input";
import CF from "../common/common-functions";
import BlockClock from './block-clock-megatimer';
import { Swiper, SwiperSlide } from 'swiper/vue';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper';

import $ from "jquery";
import Inputmask from "inputmask";

export default {
  name: "block-main",
  components: { CallbackInput, BlockClock, Swiper, SwiperSlide },
  mixins: [Mixin, finance],
  setup() {
    const prev = ref(null);
    const next = ref(null);
    return {
    modules: [Navigation],
        prev,
        next,
    };
  },
  data() {
    let today = new Date();
    return {
      swiper: null,
      date: CF.getDateString(),
      array_of_slides: [ 1, 8, 3],
      cfo: false,
      is_lb: false,
      advantages: [
        {
          text: "Быстрая<br> сделка",
          id: "fast-deal",
        },
        {
          text: "Кредит<br> от&nbsp;0.01%",
          id: "percent",
        },
        {
          text: "ПТС и автомобили<br> в наличии",
          id: "pts",
        },
        {
          text: "Лет гарантии<br> на&nbsp;новый geely",
          id: "five",
        },
        {
          text:
            'Скидка <span style="white-space: nowrap">145 000 ₽</span><br> для&nbsp;юр.&nbsp;лиц',
          id: "discount",
        },
        {
          text: "Оплата проезда<br> до&nbsp;салона",
          id: "free-ride",
        },
      ],
    };
  },
  created() {
    function getURLParameter(name) {
      var url = window.location.href;
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      var results = new RegExp("[?&]" + name + "=?([^&#]*)").exec(
        url || window.location.href
      );
      return results == null ? null : results[1] || true;
    }
    if (getURLParameter("region=cfo")) {
      this.cfo = true;
    }
    this.getBenefits();
  },
  mounted() {
    window.addEventListener("click", (event) => {
      if (!event.target || (!event.target.id && !event.target.classList)) {
        return;
      }
      if (
        event.target.id === "btn-common-1" ||
        event.target.id === "btn-common-2" ||
        event.target.id === "btn-common-3" ||
        event.target.classList.value.toString().indexOf("advantage") !== -1
      ) {
        this.getCall({ type: "sale", form: "main" });
      }
    });
  },
  watch: {
    device_platform() {
      if (this.swiper) {
        this.swiper.destroy();
        this.swiper = null;
        this.$nextTick(this.initSwiper);
      }
    },
  },
  methods: {
    getAgreement() {
      emitter.emit("getAgreement");
    },
    initSwiper() {
      if (this.swiper) return;
      // this.swiper = new Swiper(".swiper-container-main", {
      //   loop: this.array_of_slides.length > 1,
      //   autoHeight: false,
      //   simulateTouch: false,
      //   autoplay:
      //     this.array_of_slides.length > 1
      //       ? {
      //           delay: 15000,
      //         }
      //       : false,
      //   navigation: {
      //     nextEl: ".arrow-right",
      //     prevEl: ".arrow-left",
      //   },
      // });

      // if (this.swiper.loopedSlides) {
      //   //повесить маску на input в дубликате
      //   let dublicate = $(".swiper-slide-duplicate"),
      //     dublicate_inputs = dublicate.find("input[type=tel]");
      //
      //   dublicate_inputs.each((index) => {
      //     let el = dublicate_inputs.get(index),
      //       elem = $(el);
      //
      //     Inputmask({ mask: "(+7|8) (999) 999-9999" }).mask(el);
      //     elem
      //       .on("focus", () => {
      //         if (!el.value.length) {
      //           setTimeout(() => {
      //             el.setSelectionRange(4, 4);
      //           }, 0);
      //         }
      //       })
      //       .on("blur", () => {
      //         if (el.value.length < 5) {
      //           el.value = "";
      //         }
      //       });
      //   });
      //
      //   //перевесить megatimer на баннер, если он есть. В нижнем коде учитывается, что номер первого слайда - 1,
      //   //а блок с мегатаймером имеет id timer_block
      //
      //   setTimeout(() => {
      //       let dublicate = $('.swiper-slide-duplicate'),
      //           dublicate_content = dublicate.find('.content-container._1'),
      //           dublicate_bclock = dublicate_content.find('.block-clock-container').find('.bclock-clock'),
      //           clock = $('#timer_block');
      //
      //       dublicate_bclock.empty();
      //
      //       dublicate_bclock.get(0).appendChild(clock.clone().get(0));
      //   }, 5000);
      // }
    },
    getBenefits(){
      const today = new Date();
      if (today.getHours() >= '18' || today.getHours() < '10') {
        this.is_lb= !this.is_lb
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import "../styles/constants.scss";

.uppercase {
  text-transform: uppercase;
}
.desktop {
  ._4,
  ._5,
  ._6,
  ._7 {
    .advantage-text {
      text-shadow: 0 0 3px #000;
    }
    .highlight {
      text-shadow: 0px 0px 20px #000;
    }
  }
}
// .webp {
//   .mobile {
//     .content-container {
//       height: calc(100vw * 600 / 1920 + 300px);
//       &._1,
//       &._3 {
//         background-size: 130%;
//         background-position: top;
//       }
//     }
//   }
// }
.mobile {
  .content-container {
    &._1 {
      height: calc(100vw * 700 / 800 + 250px);
      background-image: url("../images/background/800х700_7_april.jpg");
      background-size: 100%;
      background-position: top;
      .header {
        background: none;
        margin-top: 15px;
      }
      .bunner-title{
        font-size: 30px;
      }
    }
    &._0 {
      height: calc(100vw * 700 / 800 + 250px);
      background-image: url("../images/background/bg-july-mobile.jpg");
      background-size: 100%;
      background-position: top;
      .header {
        background: none;
        margin-top: 15px;
      }
      .bunner-title{
        font-size: 30px;
      }
    }
    &._2 {
      height: calc(100vw * 700 / 800);
      background-image: url("../images/background/bez-nacenki_june_800_700.jpg");
      background-size: 100%;
      background-position: top;
      .header {
        color: #000;
      }
    }
    &._3 {
      height: calc(100vw * 700 / 800);
      background-image: url("../images/background/preim_800х700.jpg");
      background-size: 100%;
      background-position: top;
      .header {
        color: #000;
      }
    }
    &._4 {
      height: calc(100vw * 700 / 800 + 320px);
      background-image: url("../images/background/sales_geely_atlas_800_700.jpg");
      background-size: 100%;
      background-position: top;
      .header {
        background: none;
      }
    }
    &._5 {
      height: calc(100vw * 700 / 800 + 220px);
      background-image: url("../images/background/no_time_to_postpone_800_700.jpg");
      background-size: 100%;
      background-position: top;
      .header {
        background: none;
      }
    }
    &._6 {
      height: calc(100vw * 700 / 800 + 250px);
      background-image: url("../images/background/without_m_atlas_pro_800x700.jpg");
      background-size: 100%;
      background-position: top;
      .header {
        background: none;
        margin-top: 15px;
      }
      .bunner-title{
        font-size: 27px;
      }
    }
    &._7 {
      height: calc(100vw * 700 / 800 + 250px);
      background-image: url("../images/background/without_m_tugella_800х700.jpg");
      background-size: 100%;
      background-position: top;
      .header {
        background: none;
        margin-top: 15px;
      }
      .bunner-title{
        font-size: 30px;
      }
    }
    &._8 {
      height: calc(100vw * 700 / 800 + 250px);
      background-image: url("../images/background/monjaro_space_800x700.jpg");
      background-size: 100%;
      background-position: top;
      .header {
        background: none;
        margin-top: 15px;
      }
      .bunner-title{
        font-size: 30px;
      }
    }
  }
  .header-container {
    margin-top: calc(100vw * 700 / 800);
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .header {
    color: #000;
    font-size: 20px;
    line-height: 30px;
    background: transparent;
  }


  .callback-input {
    margin-top: 0;
  }
}

.tablet {
  ._1,
  ._4,
  ._5 {
    .advantage-text {
      text-shadow: 0 0 3px #000;
    }
    .highlight {
      text-shadow: 0px 0px 20px #000;
    }
  }
  .header {
    font-size: 36px;
    line-height: 42px;
  }
  .content-container {
    padding-left: 20px;
    padding-right: 20px;
    height: 650px;
    &._1,
    &._2,
    &._3,
    &._4,
    &._5 {
      .advantage-icon {
        &.fast-deal,
        &.pts,
        &.five,
        &.discount,
        &.free-ride {
          background-position: center;
        }
      }
    }
    &._1 {
      background-position-x: 71%;
    }
    &._2 {
      background-position: 62% center;
    }

    &._3 {
      .header {
        max-width: 90%;
        text-shadow: 1px 2px 3px #fff;
      }
    }
  }
  .advantage-wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px 12px;
  }
  .advantage-icon {
    height: 50px;
    &.pts {
      width: 80px;
    }
  }
  .advantage-text {
    margin-left: 0;
    margin-top: 10px;
    font-size: 16px;
  }
}

.block-main-container {
  width: 100%;
  position: relative;
  max-width: 1920px;
}

.swiper-container-main {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.highlight {
  color: $blue;
  font-weight: bold;
}

.highlight-black {
  color: #000;
  font-weight: bold;
}

.highlight-white {
  color: #fff;
  font-weight: bold;
  @media (max-width: 800px) {
    color: #000;
  }
}
.subtitle {
  font-size: 50px;
}

// .webp {
//   .content-container {
//     &._1 {
//       background: url("../images/background/geely-coolray_1920x650.webp") no-repeat center;
//       background-size: cover;
//     }
//     &._2 {
//       background: url("../images/background/geely_1920x650b.webp") no-repeat center;
//       background-size: cover;
//       background-position: 60% center;
//     }
//     &._3 {
//       background: url("../images/background/bg-march-2.webp") no-repeat center right;
//       background-size: cover;
//     }
//   }
// }

.content-container {
  position: relative;
  width: 100%;
  max-width: 1920px;
  margin: auto;
  height: calc(100vw * 650 / 1920);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &._1 {
    background: url("../images/background/1920х650_7_april.jpg") no-repeat center;
    background-size: cover;
    background-position-x: right;
    .header {
      /*background: rgba(0, 0, 0, 0.7);*/
      background: rgba(255, 255, 255, 0.5);
      padding: 10px 20px;
      margin-top: 15px;
/*      margin-top: 15px;*/
      max-width: fit-content;
    }
    .bunner-title{
      font-size: 55px;
      font-weight: bold;
    }
    .banner-subtitle {
      margin-top: 12px;
    }
    .advantage-icon {
      @each $adv in fast-deal, pts, five, discount, free-ride, percent {
        &.#{$adv} {
          background: url("../images/advantages/#{$adv}-white.png")
            no-repeat
            top
            right;
          background-size: contain;
        }
      }
    }
  }
  &._0 {
    background: url("../images/background/bg-july-new.jpg") no-repeat center;
    background-size: cover;
    background-position-x: right;
    .header {
      /*background: rgba(0, 0, 0, 0.7);*/
      padding: 10px 20px;
      margin-top: 80px;
      /*margin-top: 15px;*/
      max-width: fit-content;
    }
    .bunner-title{
      font-size: 55px;
      font-weight: bold;
    }
    .advantage-icon {
      @each $adv in fast-deal, pts, five, discount, free-ride, percent {
        &.#{$adv} {
          background: url("../images/advantages/#{$adv}-white.png")
            no-repeat
            top
            right;
          background-size: contain;
        }
      }
    }
  }
  &._2 {
    background: url("../images/background/bez-nacenki_june_1920_650.jpg") no-repeat center
      left;
    background-size: cover;
    background-position: 84% center;
    .header {
      color: #fff;
      /*color: #fff;*/
      margin-top: 8%;
      text-align: center;
    }
    .advantage-icon {
      @each $adv in fast-deal, pts, five, discount, free-ride, percent {
        &.#{$adv} {
          background: url("../images/advantages/#{$adv}-black.png")
            no-repeat
            top
            right;
          background-size: contain;
        }
      }
    }
    .advantage-text {
      color: #000;
    }
  }
  &._3 {
    background: url("../images/background/preim_1920х650.jpg") no-repeat center
      right;
    background-size: cover;
    .header {
      color: #fff;
    }
    .advantage-icon {
      @each $adv in fast-deal, pts, five, discount, free-ride, percent {
        &.#{$adv} {
          background: url("../images/advantages/#{$adv}-black.png")
            no-repeat
            top
            right;
          background-size: contain;
        }
      }
    }
    .advantage-text {
      color: #000;
    }
  }
  &._4 {
    background: url("../images/background/sales_geely_atlas_1920_650.jpg")
      no-repeat center;
    background-size: cover;
    // background-position-x: right;
    .header {
      background: rgba(0, 0, 0, 0.7);
      padding: 10px 20px;
      margin-top: 15px;
      max-width: fit-content;
    }
    .advantage-icon {
      @each $adv in fast-deal, pts, five, discount, free-ride, percent {
        &.#{$adv} {
          background: url("../images/advantages/#{$adv}-white.png")
            no-repeat
            top
            right;
          background-size: contain;
        }
      }
    }
  }
  &._5 {
    background: url("../images/background/no_time_to_postpone_1920_650.jpg") no-repeat center;
    background-size: cover;
    // background-position-x: right;
    .header {
      background: rgba(0, 0, 0, 0.7);
      padding: 10px 20px;
      margin-top: 15px;
      max-width: fit-content;
    }
    .advantage-icon {
      @each $adv in fast-deal, pts, five, discount, free-ride, percent {
        &.#{$adv} {
          background: url("../images/advantages/#{$adv}-white.png")
            no-repeat
            top
            right;
          background-size: contain;
        }
      }
    }
  }
  &._6 {
    background: url("../images/background/without_m_atlas_pro_1920x650.jpg") no-repeat center;
    background-size: cover;
    background-position-x: right;
    .header {
      /*background: rgba(0, 0, 0, 0.7);*/
      padding: 10px 20px;
      margin-top: 80px;
      max-width: fit-content;
    }
    .bunner-title{
      font-size: 49px;
      font-weight: bold;
    }
    .advantage-icon {
      @each $adv in fast-deal, pts, five, discount, free-ride, percent {
        &.#{$adv} {
          background: url("../images/advantages/#{$adv}-white.png")
            no-repeat
            top
            right;
          background-size: contain;
        }
      }
    }
  }
  &._7 {
    background: url("../images/background/without_m_tugella_1920х650.jpg") no-repeat center;
    background-size: cover;
    background-position-x: right;
    .header {
      /*background: rgba(0, 0, 0, 0.7);*/
      padding: 10px 20px;
      margin-top: 80px;
      max-width: fit-content;
    }
    .bunner-title{
      font-size: 55px;
      font-weight: bold;
    }
    .advantage-icon {
      @each $adv in fast-deal, pts, five, discount, free-ride, percent {
        &.#{$adv} {
          background: url("../images/advantages/#{$adv}-white.png")
            no-repeat
            top
            right;
          background-size: contain;
        }
      }
    }
  }
  &._8 {
    background: url("../images/background/monjaro_space_1920x650.jpg") no-repeat center;
    background-size: cover;
    background-position-x: right;
    .header {
      /*background: rgba(0, 0, 0, 0.7);*/
      background: rgba(255, 255, 255, 0.5);
      padding: 10px 20px;
      margin-top: 32px;
/*      margin-top: 15px;*/
      max-width: fit-content;
    }
    .bunner-title{
      font-size: 55px;
      font-weight: bold;
    }
  }
}

.advantage-icon {
  width: 103px;
  height: 65px;
  &.five {
    width: 38px;
  }
  &.discount {
    width: 44px;
  }
  &.free-ride {
    width: 46px;
  }
  &.percent {
    height: 55px;
    width: 65px;
  }
}

.header-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}

.header {
  // font-size: 35px;
  font-size: 36px;
  line-height: 40px;
  color: #000;
  // padding-top: 25px;
  padding: 25px;
  margin-bottom: 20px;
  margin-top: 20px;
  max-width: fit-content;
  /*background: #000000a8;*/
}

.header-white {
  color: #fff;
}

.btn-blue {
  border: none;
}

.bottom-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  justify-content: space-between;
}

.advantage-wrapper {
  display: flex;
  flex-direction: row;
  color: #fff;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 22px;
  padding: 20px 10px;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
  &:first-of-type {
    padding-left: 0;
  }
  &:last-of-type {
    padding-right: 0;
  }
}

.advantage-text {
  color: #fff;
  margin-left: 20px;
}

.arrow {
  position: absolute;
  top: 50%;
  width: 49px;
  height: 80px;
  transform: translateY(-50%);
  z-index: 5;
  cursor: pointer;
  outline: none;
  &:active {
    opacity: 0.5;
  }
}

.arrow-left {
  background: url("../images/utils/arrow-left.png") no-repeat center;
  left: 20px;
}

.arrow-right {
  background: url("../images/utils/arrow-right.png") no-repeat center;
  right: 20px;
}

.input-container {
  width: 320px;
  background: #fff;
  padding: 20px 20px;
}

.callback-input {
  max-width: 400px;
  @media (max-width: 1450px) {
    margin-top: auto;
  }
}

@media screen and (max-width: 1200px) {
  ._2 .header {
    display: none;
  }
}

@media screen and (min-width: 1441px) {
  .advantage-wrapper-percent {
    padding: 20px 30px;
  }
}
@media screen and (max-width: 1440px) {
  .desktop {
    .advantage-wrapper {
      font-size: 20px;
    }
  }
}
@media screen and (max-width: 1440px) {
  .desktop {
    .content-container {
      padding-right: 10px;
      padding-left: 10px;
    }
    .advantage-wrapper {
      font-size: 17px;
    }
    .advantage-text {
      margin-left: 10px;
    }
  }

  ._2 .header {
    font-size: 25px;
  }
}
@media screen and (max-width: 1750px) {
  .content-container._1 .header {
    margin-top: 10px;
  }
}


</style>
