<script>
const SIZES = {
  default: 'w-20 h-20',
  s: 'w-5 h-5'
};
const ITEM_SIZES = {
  default: 'w-16 h-16',
  s: 'w-4 h-4'
};

const DEFAULT_COLOR = '#95ca51';

export default {
  props: {
    size: {
      type: String
    },
    dropMargins: {
      type: Boolean,
      default: false
    },
    removeBg: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: DEFAULT_COLOR
    }
  },
  computed: {
    wrapperSizes(){
      return SIZES[this.size || 'default'];
    },
    itemSizes(){
      return ITEM_SIZES[this.size || 'default'];
    },
  }
}
</script>

<template>
  <div class="absolute top-0 left-0 w-full h-full flex items-center justify-center">
    <div v-if="!removeBg" class="absolute top-0 left-0 w-full h-full bg-white opacity-80"></div>
    <div 
      class="lds-ring inline-block relative"
      :class="wrapperSizes"
      :style="{
        '--color': color
      }">
      <div 
        v-for="n in 4"
        :key="n"
        class="block absolute rounded-full border-8"
        :class="[itemSizes, {'m-2': !dropMargins}]"></div>
    </div>
  </div>
</template>

<style lang="css" scoped>
.lds-ring div {
  border-color: var(--color);
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--color) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
