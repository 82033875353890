<template>
  <div class="bg-[#212232] h-[295px] md:h-[290px] xl:h-[270px] px-[9%] md:px-[5%]">
    <div class="py-[66px] justify-between flex-row hidden md:flex">
      <div class="flex flex-row gap-[40px] w-full 2xl:justify-start items-center py-[16px] md:justify-between">
        <div class="w-[165px] h-[26px] shrink-0 md:hidden 2xl:flex">
          <img
            src="@/images/logos/kuntsevo-white.png"
            alt="white logo"
            class="w-full h-full"
          />
        </div>
        <div class="uppercase text-white cursor-pointer md:text-[14px]" data-href="cars">модельный ряд</div>
        <div class="uppercase text-white cursor-pointer md:text-[14px]" data-href="configurator">конфигуратор</div>
        <div class="uppercase text-white cursor-pointer md:text-[14px]" data-href="in_stock">Geely в наличии</div>
        <div class="uppercase text-white cursor-pointer md:text-[14px]" data-href="contacts">контакты</div>
      </div>
      <buttons--button-modal-caller
        modal-title="РАССЧИТАТЬ КРЕДИТ"
        modal-button="РАССЧИТАТЬ"
        class="flex cursor-pointer text-white bg-[#002E5D] w-[204px] uppercase justify-center items-center md:hidden 2xl:flex"
      >
        РАССЧИТАТЬ КРЕДИТ
      </buttons--button-modal-caller>
    </div>
    <div class="absolute w-full h-px bg-[#E6E6E6]/[.8] left-0"></div>
    <div class="flex justify-between py-[60px] flex-col  md:flex-row md:py-[22px] items-center md:items-start">
      <div class="flex flex-col gap-[30px] md:gap-[40px] md:flex-row items-center md:items-start">
        <a
          :href="'tel:' + getDealerById(1).phone.replace(/\s|\+|\(|\)|\-/g, '')"
          class="text-white text-[14px]"
        >
          {{ getDealerById(1).phone }}
        </a>
        <p class="text-white text-[14px]">
          ООО АТЦ «Кунцево», ИНН&nbsp;5032271852, ОГРН&nbsp;1135032010310<br>
          143025, Московская область, город Одинцово, с. Немчиновка, ул. Московская, д. 61
        </p>
      </div>
      <div class="mt-[30px] md:mt-0">
        <buttons--button-modal-caller modal-title="ЗАКАЗАТЬ ЗВОНОК" modal-button="ЗАКАЗАТЬ" class="text-white underline text-[14px] cursor-pointer">
          ЗАКАЗАТЬ ЗВОНОК
        </buttons--button-modal-caller>
      </div>
    </div>
  </div>
  <div class="bg-[#2F3044] md:h-[60px]  md:px-[5%]">
    <div class="flex flex-col justify-between items-center h-full md:flex-row">
      <p
        class="text-white/30 underline cursor-pointer py-[22px] md:py-0"
        @click="modalStore.showModal({component: 'LegalInfo'})"
      >
        Подробные условия акции
      </p>
      <div class="flex flex-row gap-[30px] py-[22px] md:py-0">
        <a target="_blank" :href="item.link" v-for="item in getDealerById(1).socials">
          <img :src="require(`../images/social/sketch/${item.name}.png`)" :alt="item.name" />
        </a>
      </div>
      <buttons--button-modal-caller
        modal-title="РАССЧИТАТЬ КРЕДИТ"
        modal-button="РАССЧИТАТЬ"
        class="flex cursor-pointer text-white bg-[#002E5D] md:w-[204px] uppercase justify-center items-center md:flex 2xl:hidden md:h-full py-[22px] md:py-0 w-full"
        >
          рассчитать кредит
      </buttons--button-modal-caller>
    </div>
  </div>
</template>
<script setup>
import { storeToRefs } from "pinia";
import { useDealerStore } from "@/stores/dealerStore";
import { useModalStore } from "@/stores/modalStore";

const dealerStore = useDealerStore();
const modalStore = useModalStore()
const { getDealerById } = storeToRefs(dealerStore);
</script>
