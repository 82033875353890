<template>
    <form class="callback-form CKiForm" @submit.prevent="submitThis" :class="[device_platform, {'horizontal': horizontal}, form_class]">
        <div class="input-block" :class="input_class">
            <input name=”name”
                   type="text"
                   placeholder="Ваше имя"
                   :title="'Введите Ваше имя'"
                   v-model="name"
                   ref="name"
                   v-if="data.form === 'coolray'"
                   required>
            <input name=”tel”
                   type="tel"
                   :placeholder="[data.placeholder ?? 'Ваш телефон']"
                   :title="'Введите номер телефона'"
                   v-model="phone"
                   ref="phone"
                   v-mask
                   :pattern="'.*[0-9]{1}.*[0-9]{3}.*[0-9]{3}.*[0-9]{4}'"
                   class="bg-transparent min-h-[70px]"
                   required>
            <button type="submit"
                    :disabled="!acceptance || phone.length === 0"
                    class="btn-blue CKFormTrigger"
                    :class="[{'disabled': !acceptance || phone.length === 0}, btn_class]"
                    ref="submitter">
                <slot></slot>
            </button>
        </div>
        <div class="agreement" :class="[data.form === 'timer' ? 'self-center' : 'self-start']"
             v-if="CONSTANTS.need_agreement">
            <checkbox :value="acceptance" @input="acceptance = $event"/>
            <div class="agreement-confirm"><span @click="modalStore.showModal({component: 'ModalMessage', type: 'agreement'})">Даю согласие</span> на обработку моих персональных данных</div>
        </div>
    </form>
</template>

<script>
    import Mixin from '@/common/mixin';
    import Mask from '@/common/mask';
    import Checkbox from '../checkbox';
    import emitter from 'tiny-emitter/instance'
    import { useModalStore } from "@/stores/modalStore";
    export default {
        name: "callback-input",
        components: {Checkbox},
        directives: {Mask},
        mixins: [Mixin],
        props: ['horizontal', 'data', 'form_class', 'input_class', 'btn_class'],
        setup() {
            const modalStore = useModalStore();
            return {
            modalStore,
            };
        },
        data() {
            return {
                acceptance: true,
                phone: '',
                name: '',
            }
        },
        watch: {
            name: function() {
              if (this.name.trim().length > 3) {
                  this.$refs.name.setCustomValidity('');
              }
          }
        },
        methods: {
            submitThis(event) {
                if (this.$refs.phone.validity.valueMissing || !this.$refs.phone.validity.valid) {
                    this.$refs.phone.setCustomValidity('Введите номер телефона');
                }

                if (!this.$refs.phone.validity.valueMissing && !this.$refs.phone.validity.patternMismatch) {
                    this.$refs.phone.setCustomValidity('');
                }

                if (this.data.form === 'coolray' && this.name.trim().length < 3) {
                    this.$refs.name.setCustomValidity('Введите Ваше имя');
                }

                if (this.data.form === 'coolray') {
                    if (this.acceptance && this.$el.reportValidity()) {
                        if (this.CONSTANTS.cKeeper === 'iform') {
                            ckForms.send(this.$el);
                        }

                      emitter.emit('callBack', {
                            phone: this.phone,
                            data: this.data,
                            name: this.name,
                        });
                    }
                } else {
                    if (this.acceptance && this.$el.reportValidity()) {
                        if (this.CONSTANTS.cKeeper === 'iform') {
                            ckForms.send(this.$el);
                        }

                      emitter.emit('callBack', {
                            phone: this.phone,
                            data: this.data,
                        });
                    }
                }
            },
            getAgreement() {
              emitter.emit('getAgreement');
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../../styles/constants.scss";

    .tablet {

        .callback-form,
        .callback-form-contacts
         {
            .agreement {

                .agreement-confirm {
                    font-size: 11px;
                    span {

                    }
                }
            }
        }
    }

    .mobile {

    }

    .callback-form,
    .callback-form-contacts {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &.horizontal {
            .input-block {
                flex-direction: row;
                justify-content: space-between;

                input, button {
                    width: 48%;
                }
            }
        }

        &:not(.horizontal) {
            .input-block {
                flex-direction: column;

                input, button {
                    width: 100%;
                }

                input {
                    margin-bottom: 35px;
                }
            }
        }

        .input-block {
            width: 100%;
            display: flex;

            input {
                height: 50px;
                border: 1px solid #C6C6C6;
                color: black;
                text-align: center;
                font-size: 16px;
                text-transform: uppercase;
                border-radius: 2px;

                &::placeholder {
                    color: black;
                }
            }

            .btn-blue {
                border: 1px solid #C6C6C6;
            }
        }

        .agreement {
            display: flex;
            margin-top: 10px;
            //align-self: flex-start;
            align-items: center;
            text-shadow: 0 0 3px #fff;
            color: #000;

            .agreement-confirm {
                font-size: 12px;
                span {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }

.main-invert {
    .agreement {
        color: white;
        text-shadow: 0 0 3px #C6C6C6;
    }
}

.agreement-invert {
    .agreement {
        color: white;
        text-shadow: 0 0 3px #C6C6C6;
    }
}

.btn-blue {
    @apply bg-primary-blue text-white py-7 text-sm min-h-[70px] disabled:opacity-80 disabled:hover:opacity-80 ease-in-out duration-300;
}

.main {
    .input-block {
        input {
            border: 4px solid #1f6fb6;
        }
    }
}
.main-form {
    flex-flow: row!important;
}
.btn_red {
    margin-left: 20px;
    background-color: #D30043;;
}
</style>
