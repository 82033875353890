<template>
  <div class="block-clock-container" :class="[device_platform]">
    <div class="clock-wrapper px-[5%] md:px-[9%] py-[4%] xl:items-center xl:justify-center xl:gap-[65px]">
      <div class="bclock-header self-start xl:self-center basis-full xl:basis-auto ">До конца акции осталось:</div>
      <div class="bclock-clock">
        <div v-for="stat in date_parts" class="bclock-clock-item">
          <div class="bclock-clock-stat">
            <div class="values-container">
              <div
                class="value"
                v-for="(time, index) in timeElement(stat.value)"
                :id="stat.elemId + '_' + (index + 1)"
              >
                <div class="top">
                  <div>
                    {{ time }}
                  </div>
                </div>
                <div class="bottom">
                  <div>
                    {{ time }}
                  </div>
                </div>
                <div class="top back">
                  <div>
                    {{ timeElement(stat.previous)[index] }}
                  </div>
                </div>
                <div class="bottom back">
                  <div>
                    {{ timeElement(stat.previous)[index] }}
                  </div>
                </div>
              </div>
            </div>
            <div class="bclock-clock-stat__name">
              {{ stat.name }}
            </div>
          </div>
        </div>
      </div>
      <buttons--button-modal-caller
        modal-title="ПОЛУЧИТЬ ПРЕДЛОЖЕНИЕ"
        modal-button="ПОЛУЧИТЬ"
        class="text-white uppercase border border-solid border-white cursor-pointer hidden items-center md:flex md:py-[22px] md:px-[25px] 2xl:text-[15px] 2xl:px-[36px] 2xl:py-[27px] max-h-[70px] ml-auto xl:ml-0"
      >
        ПОЛУЧИТЬ ПРЕДЛОЖЕНИЕ
      </buttons--button-modal-caller>
    </div>
  </div>
</template>

<script>
import Mixin from "../common/mixin";
import CF from "../common/common-functions";
import Mask from "@/common/mask";
import CallbackInput from "./callback-form/callback-input";
import end_date from "../common/constants";
export default {
  name: "block-clock",
  components: { CallbackInput },
  directives: { Mask },
  mixins: [Mixin],
  props: [""],
  data() {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      end_date: end_date,
      date_parts: [
        {
          name: "Дней",
          units: "days",
          value: 0,
          previous: 0,
          period: 0,
          elemId: "flip-days",
        },
        {
          name: "Часов",
          units: "hours",
          value: 0,
          previous: 0,
          period: 24,
          elemId: "flip-hours",
        },
        {
          name: "Минут",
          units: "minutes",
          value: 0,
          previous: 0,
          period: 60,
          elemId: "flip-minutes",
        },
        {
          name: "Секунд",
          units: "seconds",
          value: 0,
          previous: 0,
          period: 60,
          elemId: "flip-seconds",
        },
      ],
    };
  },
  watch: {
    name: function () {
      if (this.name.trim().length > 3) {
        this.$refs.name.setCustomValidity("");
      }
    },
  },
  created() {
    let small_part_multiplier = 1,
      time_left = (end_date.end_date.getTime() - Date.now()) / 1000;
    for (let i = this.date_parts.length - 1; i >= 0; --i) {
      let part = this.date_parts[i];
      part.value = Math.floor(
        (time_left / small_part_multiplier) % (part.period || 10000)
      );
      part.previous = part.value;
      part.name = this.getName(part);
      small_part_multiplier = small_part_multiplier * part.period;
      this[part.units] = part.value;
    }
    setInterval(this.clockTick, 1000);
  },
  computed: {},
  methods: {
    clockTick() {
      for (let i = this.date_parts.length - 1; i >= 0; --i) {
        const part = this.date_parts[i];
        if (part.value > 0) {
          part.previous = part.value;
          --part.value;
          part.name = this.getName(part);
          if (this[part.units] !== part.value) {
            this.animateIt(i, part.units);
            this[part.units] = part.value;
          }
          return;
        }
        part.previous = part.value;
        part.value = part.period - 1;
        part.name = this.getName(part);
        if (this[part.units] !== part.value) {
          this.animateIt(i, part.units);
          this[part.units] = part.value;
        }
      }
    },
    getName(date_part) {
      return CF.getNumericalName(date_part.value, date_part.units);
    },
    timeElement(stat) {
      let time = [];
      if (stat.toString().length < 2) {
        time.push(0);
        time.push(stat);
      } else {
        time.push(stat.toString()[0]);
        time.push(stat.toString()[1]);
      }

      return time;
    },
    animateIt(index, unit) {
      if (index >= this.date_parts.length) {
        return;
      }
      const date = this.date_parts[index];
      let first_digit, second_digit;
      if (date && date.elemId) {
        first_digit = document.querySelector(`#${date.elemId}_1`);
        second_digit = document.querySelector(`#${date.elemId}_2`);
      }

      if (first_digit && second_digit) {
        if (
          this.timeElement(date.value)[0] !== this.timeElement(this[unit])[0]
        ) {
          first_digit.classList.add("flip");
          second_digit.classList.add("flip");
        } else if (
          this.timeElement(date.value)[1] !== this.timeElement(this[unit])[1]
        ) {
          second_digit.classList.add("flip");
        }
      }
      setTimeout(() => {
        if (first_digit.classList.contains("flip")) {
          first_digit.classList.remove("flip");
        }
        if (second_digit.classList.contains("flip")) {
          second_digit.classList.remove("flip");
        }
      }, 670);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/constants";
/*Высота отвечает за высоту шрифта в том числе*/
$height: 30px;
$width: 30px;
$border-radius: 4px;
.mobile {
  margin-bottom: 50px;
  background-color: #002e5d;
  padding-top: 34px;
  padding-bottom: 34px;

  .clock-wrapper {
    gap: 0;
    flex-wrap: wrap;
    justify-content: center;
  }
  .form-container {
    display: none;
  }
  .clock-wrapper {
    background: none;
    position: relative;

    &::after {
      background: none;
    }
  }
  .bclock-header {
    // align-self: center;
    /*color: #000;*/
    color: #fff;
    font-weight: 500;
    // font-size: 18px;
    margin-bottom: 10px;
  }
  .bclock-clock-stat {
    margin: 5px;
  }
}
.tablet {
  .form-container {
    display: none;
  }
}
.block-clock-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 200px;
  justify-content: center;
}

.clock-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0px auto;
  background: #002e5d;
  position: relative;
  width: 100%;
  right: 50%;
  transform: translateX(50%);
  z-index: 2;
  min-height: inherit;
}

.bclock-clock {
  display: flex;
  // justify-content: center;
}

.bclock-header {
  position: relative;
  z-index: 2;
  font-size: 30px;
  font-weight: 400;

  // align-self: center;
  color: #fff;
}

.bclock-clock-stat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;

  &__name {
    font-size: 12px;
    color: #fff;
  }
}

.values-container {
  display: flex;
  flex-direction: row;
}

.value {
  height: $height;
  width: $width;
  font-size: $height;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  box-shadow: 1px 2px 3px #3a3c3b;
  border-radius: 4px;
  background: #3a3c3b;
}
.top,
.bottom {
  height: 50%;
  width: 100%;
  position: absolute;
  left: 0;
  padding: 5px;
  overflow: hidden;
  line-height: 1;
  text-align: center;
}
.top:not(.back),
.bottom:not(.back) {
  z-index: 1;
}
.bottom {
  background: #3a3c3b;
  color: white;
  top: 50%;
  bottom: 0;
  border-radius: 0 0 $border-radius $border-radius;
  padding-top: 0;
  div {
    position: absolute;
    bottom: 0;
  }
}

.top {
  background: #3a3c3b;
  color: white;
  bottom: 50%;
  border-radius: $border-radius $border-radius 0 0;
  top: 0;
  padding-bottom: 0;
  div {
    position: absolute;
    top: 0;
  }
}

/*переворачивает верхнюю часть цифры сверху вниз */
@keyframes flip-top {
  0% {
    transform: rotateX(0);
    top: 0%;
    z-index: 2;
  }
  0%,
  99% {
    opacity: 1;
    z-index: 2;
  }
  100% {
    transform: rotateX(-180deg);
    top: 0%;
    opacity: 0;
    z-index: 1;
  }
}

/*переворачивает нижнюю часть цифры сверху вниз */
@keyframes flip-bottom {
  0% {
    transform: rotateX(180deg);
    top: 50%;
  }
  0%,
  99% {
    opacity: 1;
    z-index: 5;
  }
  100% {
    transform: rotateX(0);
    top: 50%;
    z-index: 1;
  }
}
.flip {
  .top {
    &.back {
      animation-iteration-count: infinite;
      animation: flip-top 0.4s linear;
      animation-fill-mode: both;
      transform-origin: center bottom;
      backface-visibility: hidden;
    }
    &:not(.back) {
      animation-direction: reverse;
      animation-fill-mode: forwards;
      animation: color-change-top 0.15s linear;
    }
  }
  .bottom {
    &.back {
      animation: color-change-bottom 0.2s linear;
      animation-fill-mode: forwards;
      animation-delay: 0.2s;
    }
    &:not(.back) {
      animation-iteration-count: infinite;
      transform-origin: center top;
      animation-fill-mode: both;
      animation: flip-bottom 0.5s linear;
      backface-visibility: hidden;
    }
  }
}

@keyframes color-change-bottom {
  0% {
    background-image: linear-gradient(#555, #3a3c3b, #3a3c3b, #3a3c3b);
  }
  25% {
    background-image: linear-gradient(#555, #3a3c3b, #3a3c3b);
  }
  50% {
    background-image: linear-gradient(#555, #3a3c3b);
  }
  75% {
    background-image: linear-gradient(#555, #555, #3a3c3b);
  }
  100% {
    background-image: linear-gradient(#555, #555);
  }
}

@keyframes color-change-top {
  0% {
    background-image: linear-gradient(#3a3c3b, #555);
  }
  50% {
    background-image: linear-gradient(#3a3c3b, #3a3c3b, #555);
  }
  100% {
    background-image: linear-gradient(#3a3c3b, #3a3c3b, #555);
  }
}
@media screen and (max-width: 1400px) {
  .clock-wrapper {
    right: 0;
    transform: none;
  }
}
.bclock-clock-separator {
  display: flex;
  flex-flow: column;
  margin-bottom: 18px;
  padding-left: 5px;
}
.bclock-clock-item {
  display: flex;
  align-items: center;
}
div.bclock-clock > div:nth-child(4) > div.bclock-clock-separator {
  display: none;
}
.dots {
  width: 5px;
  height: 5px;
  background-color: white;
}
</style>
