import CF from './common-functions'; 
import CarsInfo from './cars-info';

const prices = CarsInfo.CARS.map(el => el.price);
const advantages = CarsInfo.CARS.map(el => el.advantage);
const payments = CarsInfo.CARS.map(el => el.payment);
const MAX_ADVANTAGE = Math.max.apply(null, advantages);
const MIN_PRICE = Math.min.apply(null, prices);
const MIN_PAYMENT = Math.min.apply(null, payments);

let date = CF.getNewDate();

const PHONE = '+7 (495) 933-40-33'.replace(/\s/g, '\u00A0').replace(/-/g, '\u2011');

const PHONE_RAW = PHONE.replace(/\D+/g, "");


export default {
    phone: PHONE,
    phone_raw: PHONE_RAW,
    end_date: date,
    max_advantage: MAX_ADVANTAGE,
    min_price: MIN_PRICE,
    min_payment: MIN_PAYMENT,
    office: {
        name: '',
        address: 'ул. Горбунова, 14, Москва',
        phone: PHONE,
        phone_raw: PHONE_RAW,
        worktime: 'ОТДЕЛ ПРОДАЖ: <b>8:00-21:00</b>',
        opening_hours: '08:00 - 21:00',
        work_dates: 'Пн - Вс',
        coords: {
            x: 37.374102,
            y: 55.725929
        },
    },
    brand: 'Geely',
    dealer: 'АТЦ Кунцево',
    center_coords: {
        x: 37.372102,
        y: 55.725929
    },
    need_agreement: true,
    cKeeper: undefined,
    need_manager_phone: true
}
